import React from "react";
import "./uploadpo.css";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import { useNavigate } from "react-router-dom";
import UploadPO from "../../Components/UploadPo";

const UpLoadPoPage = () => {
  const navigate = useNavigate();
  const onClickDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <DashBoardLayout>
      <div className="container-fluid">
        <div className="main-dashboard-body upload-po-body">
          <div className="upload-po-main-page my-5">
            <h1 className="upload-po-main-heading mx-3 pt-2">
              Upload po document file
            </h1>
            <p className="upload-po-main-para mx-3">
              maximum file size 1000KB. Supported file formats: jpeg jpg png
            </p>
            <UploadPO />
          </div>
          <div className="conatiner d-flex justify-content-end align-items-center">
            <button className="upload-po-cancel-btn px-4 mx-3">Cancel</button>
            <button
              className="upload-po-upload-btn px-4"
              onClick={onClickDashboard}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default UpLoadPoPage;
