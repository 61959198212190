import React from "react";
import { useTranslation } from "react-i18next";

import PoTable from "../../Components/PoTable";
import PoCard from "../../Components/PoTable/PoCard";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./potable.css";

const PoTablePage = () => {
  const { t } = useTranslation();

  return (
    <DashBoardLayout>
      <div className="main-dashboard-body mb-3">
        <h3 className="vendor-dashboard-heading py-2 px-3">{t("PO")}</h3>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-lg-3 col-md-6 py-3">
              <PoCard title={t("All")} more={"..."} count={"65"} />
            </div>
            <div className="col-lg-3 col-md-6 py-3">
              <PoCard title={t("Confirming PO")} more={"..."} count={"5"} />
            </div>
            <div className="col-lg-3 col-md-6 py-3">
              <PoCard title={t("Delivering")} more={"..."} count={"30"} />
            </div>
            <div className="col-lg-3 col-md-6 py-3">
              <PoCard title={t("Delivered")} more={"..."} count={"30"} />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-2 mb-5">
          <PoTable />
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default PoTablePage;
