import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountryList } from "../../Store/Reducer/countryList";
import {
  CHECK_TAX_NO,
  CITY_LIST,
  STATE_LIST,
  // VENDOR_DETAILS,
  // VENDOR_UPDATE_DETAILS,
} from "../../ApiEndPoints/ApiEndPoints";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { toast } from "react-toastify";
import {
  getVendorInfo,
  latestGeneralVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { handleKeyDown, handleOnWheel } from "../../Utils/Utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const General = ({
  setEditMode,
  editMode,
  setDocEdit,
  getCommercialRegType,
  setType,
  // getGeneralInfoData,
  setPageLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.UserInfoReducer);

  const { CountryList } = useSelector((state) => state.countryList);
  const [countryCode, setCountryCode] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const [countryCode, setCountryCode] = useState("");
  const [updatedInfo, setUpdatedInfo] = useState(false);

  const {
    register,
    handleSubmit,
    // getValues,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const formatContactName = (name) => {
    // Match words (alphabetic characters) using regex and capitalize the first letter of each word
    return name?.replace(/\b\w/g, (char) => char?.toUpperCase());
  };

  useEffect(() => {
    setPageLoading(true);
    dispatch(getAllCountryList());
    dispatch(getVendorInfo());
    setPageLoading(false);
  }, [dispatch]);

  const selectedCommercialType = watch("commercial_reg_type");
  const oldCommercialType = userInfo?.commercial_reg_type;
  const commercialTypeCheck = oldCommercialType !== selectedCommercialType;
  const selectedCountry = watch("country");
  const selectedState = watch("district");

  console.log("test");

  // Check if any of the specified values are selected for commercial type
  const extractedValue =
    selectedCommercialType && selectedCommercialType.match(/\((.*?)\)/)?.[1];

  const isFalse =
    extractedValue &&
    ["O", "U", "P", "G", "N", "V", "C", "A"].includes(extractedValue);

  const isFalseThaiName = extractedValue && ["O", "U"].includes(extractedValue);

  const isFieldMandatory = extractedValue === "L";

  // console.log("isFieldMandatory", isFieldMandatory);

  // thai language validation
  const validateThaiInput = (value) => {
    // regex to accept Thai characters, numeric values, and special characters
    const thaiCharactersWithSpecial =
      /^[\u0E00-\u0E7F\d\s!@#$%^&*()_+{}\[\]:;"'<>,.?/|\\\-]*$/;

    if (!thaiCharactersWithSpecial.test(value)) {
      return "Please enter the input in Thai.";
    }

    return true;
  };

  // phone number validations
  const validatePhoneNumber = (value, isFieldMandatory) => {
    if (isFieldMandatory && !value) {
      return t("Please enter the mobile phone number.");
    }

    if (value && !isValidPhoneNumber(value)) {
      return t("Mobile number is invalid.");
    }

    return true; // Return true to indicate validation success
  };

  // Getting country Id
  const getCountryIdByName = (selectedCountry) => {
    const country = CountryList.reduce((acc, curr) => {
      if (curr.country === selectedCountry) {
        return curr;
      }
      return acc;
    }, null);

    return country ? country.id : null;
  };

  const countryId = getCountryIdByName(selectedCountry);

  // Getting state ID
  const getStateIdByName = (selectedState) => {
    const state =
      stateList.length > 0 &&
      stateList.find((s) => s.state_name === selectedState);
    return state ? state.id : null;
  };

  const stateId = getStateIdByName(selectedState);

  useEffect(() => {
    if (countryId) {
      axios
        .get(`${BaseUrl}${STATE_LIST}${countryId}`)
        .then((res) => {
          setStateList(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [countryId]);

  useEffect(() => {
    if (stateId && countryId) {
      axios
        .get(`${BaseUrl}${CITY_LIST}${stateId}&country_id=${countryId}`)
        .then((res) => {
          setCityList(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [stateId, countryId]);

  const registrationNumber = watch("registration_no");

  const oldRegistrationNumber = userInfo?.registration_no;

  const onSubmit = async (data) => {
    getCommercialRegType(data.commercial_reg_type);
    setPageLoading(true);
    var formdata = new FormData();
    formdata.append("register_country", data.register_country);
    formdata.append("vendor_type", data.vendor_type);
    formdata.append("user_name", data.user_name);
    formdata.append("commercial_reg_type", data.commercial_reg_type);
    formdata.append("registration_no", data.registration_no);
    formdata.append("registration_capital", data.registration_capital);
    formdata.append("established_date", data.established_date);
    formdata.append("user_name", data.user_name);
    formdata.append("company_name_th", data.company_name_th);
    formdata.append("company_name_eng", data.company_name_eng);
    formdata.append("title_name", data.title_name);
    formdata.append("sales_contact", data.sales_contact);
    formdata.append("telephone", data.telephone);
    formdata.append("mobile_phone", data.mobile_phone);
    formdata.append("website", data.website);
    formdata.append("contact_name", data.contact_name);
    formdata.append("contact_phone", data.contact_phone);
    formdata.append("contact_email", data.contact_email);
    formdata.append("contact_email2", data.contact_email2);
    formdata.append("email_for_vms", data.email_for_vms);
    formdata.append("email2_for_vms", data.email2_for_vms);
    formdata.append("street_house_no", data.street_house_no);
    formdata.append("city", data.city);
    formdata.append("district", data.district);
    formdata.append("country", data.country);
    formdata.append("postal_code", data.postal_code);
    formdata.append("title_specify", data.title_specify);
    formdata.append("fax", data.fax);

    const formDataObject = Object.fromEntries(formdata.entries());

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    if (oldRegistrationNumber !== registrationNumber && registrationNumber) {
      fetch(`${BaseUrl}${CHECK_TAX_NO}${registrationNumber}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setPageLoading(false);
          if (result.data.registation_status === false) {
            //     setEditMode(false);

            if (
              commercialTypeCheck &&
              ["L", "T", "P"].includes(extractedValue)
            ) {
              setType("Documents");
              dispatch(latestGeneralVendorInfo(formDataObject));
              setDocEdit(true);
              setPageLoading(false);
            } else {
              dispatch(updateVendorInfo(formdata))
                .then(() => {
                  dispatch(getVendorInfo())
                    .then(() => {
                      setEditMode(false);
                      setPageLoading(false);
                      toast.success("Updated Successfully");
                    })
                    .catch((error) => {
                      toast.error(error);
                      setPageLoading(false);
                    });
                })
                .catch((error) => {
                  toast.error(error);
                  setPageLoading(false);
                });
            }
          } else {
            toast.error(
              "This registration number already exists please change and try again"
            );
          }
        })
        .catch((error) => toast.error(error));
    } else {
      if (commercialTypeCheck && ["L", "T", "P"].includes(extractedValue)) {
        setType("Documents");
        dispatch(latestGeneralVendorInfo(formDataObject));
        setPageLoading(false);
        setDocEdit(true);
      } else {
        try {
          const isSuccess = await dispatch(updateVendorInfo(formdata));

          if (isSuccess?.data?.status === 200) {
            await dispatch(getVendorInfo());
            setEditMode(false);
            setPageLoading(false);
            toast.success(isSuccess?.data?.message);
          } else {
            setPageLoading(false);
            toast.error(isSuccess?.data?.message || "Something went wrong");
          }
        } catch (error) {
          console.log(error, "Error");
          toast.error(error.message || "Something went wrong");
          setPageLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getVendorInfo());
  }, [updatedInfo === true]);

  useEffect(() => {
    if (userInfo) {
      Object.keys(userInfo).forEach((key) => {
        setValue(key, userInfo[key]);
      });
      // console.log(userInfo?.established_date);
      setValue("established_date", userInfo?.established_date?.split("T")[0]);
      setValue("district", userInfo?.district);
    }
  }, [userInfo]);

  // console.log("User info @@@@", userInfo);

  useEffect(() => {
    var domestic = watch("register_country");
    if (domestic === "Thailand") {
      setValue("vendor_type", "Domestic");
    } else {
      setValue("vendor_type", "Oversea");
    }
  }, [watch("register_country")]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div className="vp-label-input">
            <label className="vp-label">{t("Vendor Code")} :</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                {...register("vendor_code", {
                  // required: true,
                  // maxLength: 10,
                })}
              />
              {/* {errors.vendor_code && (
                                                        <span className="error-msg">Please enter the value</span>
                                                )} */}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Registered Country")}
              {editMode && <span className="staricon">*</span>} :{" "}
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("register_country", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select country")}
                </option>
                {CountryList &&
                  CountryList?.map((country, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        key={index}
                        value={country.country}
                      >
                        {country.country}
                      </option>
                    );
                  })}
              </select>
              {errors.register_country && (
                <span className="error-msg">
                  {t("Please select the register country.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Vendor Type")}
              {editMode && <span className="staricon">*</span>} :{" "}
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("vendor_type", {
                  required: true,
                })}
              >
                <option value="Domestic">{t("Domestic")} </option>
                <option value="Oversea">{t("Oversea")}</option>
              </select>
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {" "}
              {t("Commercial Register Type")}
              {editMode && <span className="staricon">*</span>}:
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("commercial_reg_type", {
                  required: true,
                })}
              >
                <option value="" hidden>
                  {t("select commerical register type")}
                </option>
                <option value="(L) Domestic Legal Entity">
                  (L) Domestic Legal Entity(นิติบุคคล/บริษัทในประเทศ){" "}
                </option>

                <option value="(P) Domestic Individual person">
                  (P) Domestic Individual person(บุคคลธรรมดาในประเทศ){" "}
                </option>

                <option value="(O) Oversea">
                  (O) Oversea(บริษัทต่างประเทศ){" "}
                </option>

                <option value="(T) Transportation Domestic">
                  (T) Transportation Domestic(บริษัทขนส่งภายในประเทศ){" "}
                </option>
                <option value="(U)Transportation Oversea">
                  (U) Transportation Oversea(บริษัทขนส่งต่างประเทศ){" "}
                </option>
                <option value="(G) Government">
                  (G) Government(หน่วยงานรัฐบาล){" "}
                </option>
                <option value="(N) Non-Government">
                  (N) Non-Government(องค์กรไม่แสวงหากำไร เช่น สโมสร,สมาคม){" "}
                </option>
                <option value="(V) VAT report only">
                  (V) VAT report only(ใช้เฉพาะฝ่ายบัญชีเท่านั้น){" "}
                </option>
                <option value="(C) Customer Claim">(C) Customer Claim </option>
                <option value="(A) Internal">
                  (A) Internal(บริษัทในเครือ AGC)
                </option>
              </select>
              {errors.commercial_reg_type && (
                <span className="error-msg">
                  {t("Please select the commercial register type.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Registration No. (TAX Number)")}
              {!isFalse && editMode && <span className="staricon">*</span>}:
            </label>
            <div className="vp-input-div">
              <input
                type="number"
                disabled={!editMode}
                className="vp-input"
                {...register("registration_no", {
                  required: !isFalse,
                  maxLength: 13,
                  minLength: 13,
                })}
                onWheel={handleOnWheel}
              />
              {errors.registration_no && (
                <span className="error-msg">
                  {t("Please enter the 13 digits registration no.(Tax number)")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Registered Capital (THB)")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("registration_capital", {
                  required: isFieldMandatory,
                  maxLength: 20,
                })}
              />
              {errors.registration_capital && (
                <span className="error-msg">
                  {t("Please enter the registered capital.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Established Date")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="date"
                className="vp-input"
                max={new Date().toISOString().split("T")[0]}
                value={watch("establishedDate")}
                {...register("established_date", {
                  required: isFieldMandatory,
                })}
              />
              {errors.established_date && (
                <span className="error-msg">
                  {t("Please enter the establishment date.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">{t("Username")}:</label>
            <div className="vp-input-div">
              <input
                type="email"
                disabled
                className="vp-input"
                {...register("user_name", {
                  // required: true,
                  // maxLength: 80,
                })}
              />
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">{t("Password")}:</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                value="**********"
                className="vp-input"
                {...register("password", {})}
              />
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Company Name (TH)")}
              {watch("vendor_type") !== "Oversea" &&
                !isFalseThaiName &&
                editMode && <span className="staricon">*</span>}
              :
            </label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled={!editMode || watch("vendor_type") === "Oversea"}
                placeholder={
                  watch("vendor_type") === "Oversea" ? "" : "Enter Thai text"
                }
                className="vp-input"
                {...register("company_name_th", {
                  required:
                    watch("vendor_type") !== "Oversea" && !isFalseThaiName,
                  validate: validateThaiInput,
                  maxLength: 80,
                })}
              />
              {errors.company_name_th && (
                <span className="error-msg">
                  {t("Please enter the company name in Thai.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Company Name (EN)")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled={!editMode}
                className="vp-input"
                {...register("company_name_eng", {
                  required: isFieldMandatory,
                  maxLength: 160,
                  minLength: 1,
                })}
              />
              {errors.company_name_eng && (
                <span className="error-msg">
                  {t("Please enter the company name in English.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">{t("Search Name")}:</label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled
                className="vp-input"
                value={watch("company_name_eng")}
              />
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Title Name")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <select
                type="text"
                disabled={!editMode}
                className="vp-input"
                {...register("title_name", {
                  required: isFieldMandatory,
                })}
              >
                <option value="" hidden>
                  {t("select")}
                </option>
                <option value="Public Company Limited">
                  {t("Public Company Limited")}
                </option>
                <option value="Company Limited">{t("Company Limited")}</option>
                <option value="partnership">{t("Partnership")}</option>
                <option value="miss">{t("MR./MS/MISS/ Etc.")}</option>
                <option value="other">{t("Other please specify")} </option>
              </select>
              {errors.title_name && (
                <span className="error-msg">
                  {t("Please select the company title prefix or suffix.")}
                </span>
              )}

              <div
                className="vp-input-div"
                style={{
                  display: "contents",
                }}
              >
                {watch("title_name") === "other" && (
                  <>
                    <input
                      type="text"
                      disabled={!editMode}
                      className="vp-input mt-3"
                      {...register("title_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.title_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="vp-label-input">
            <label className="vp-label">
              {t("Sale or Marketing Contact email")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                type="email"
                placeholder={t("enter contact email address")}
                disabled={!editMode}
                className="vp-input"
                {...register("sales_contact", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.sales_contact &&
                errors.sales_contact.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.sales_contact && (
                <span className="error-msg">
                  {t(
                    "Please enter the contact person's name, sales or marketing department."
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Tel.")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="number"
                className="vp-input"
                {...register("telephone", {
                  required: isFieldMandatory,
                  maxLength: 20,
                  minLength: 9,
                })}
                onWheel={handleOnWheel}
              />
              {errors.telephone && (
                <span className="error-msg">
                  {t("Please enter the telephone number.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Mobile phone")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            {editMode ? (
              <div className="vp-input-div">
                <Controller
                  name="mobile_phone"
                  control={control}
                  rules={{
                    validate: (value) =>
                      validatePhoneNumber(value, isFieldMandatory),
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      // country={countryCode ? countryCode.toLowerCase() : "th"}
                      defaultCountry={countryCode ? countryCode : "TH"}
                      disabled={!editMode}
                      className="vp-input"
                    />
                  )}
                />
                {errors.mobile_phone && (
                  <span className="error-msg">
                    {errors.mobile_phone.message}
                  </span>
                )}
              </div>
            ) : (
              <div className="vp-input-div">
                <input
                  disabled={!editMode}
                  type="text"
                  className="vp-input"
                  {...register("mobile_phone", {
                    required: isFieldMandatory,
                  })}
                />
              </div>
            )}
          </div>

          <div className="vp-label-input">
            <label className="vp-label">{t("Fax Number")} :</label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="number"
                className="vp-input"
                {...register("fax", {
                  pattern: {
                    value: /^[0-9]{9,20}$/, // Added a pattern to ensure only digits between 9 to 20 are allowed
                    message:
                      "Please enter a valid fax number with 9 to 20 digits",
                  },
                })}
                onWheel={handleOnWheel}
              />
              {errors.fax && (
                <span className="error-msg">{errors.fax.message}</span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">{t("Web Site (If any)")} :</label>
            <div className="vp-input-div">
              <input
                type="url"
                disabled={!editMode}
                className="vp-input"
                {...register("website", {
                  pattern: {
                    value: /^(https?:\/\/)?[a-z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/i,
                    message: "Invalid website URL",
                  },
                })}
              />
              {errors.website && (
                <span className="error-msg">{errors.website.message}</span>
              )}
            </div>
          </div>
          <h3 className="vendor-profile-contact">
            {t("Contact person for (Account)")}{" "}
          </h3>
          <hr className="contact-line"></hr>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Name")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("contact_name", {
                  required: isFieldMandatory,
                  maxLength: 50,
                  pattern:
                    /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~]+$/,
                  onChange: (e) => {
                    const enteredName = e.target.value;
                    // const formattedName = formatContactName(enteredName);
                    // e.target.value = formattedName;
                  },
                })}
              />
              {errors.contact_name && (
                <span className="error-msg">
                  {t("Please enter the contact person name for account")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Mobile phone")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            {editMode ? (
              <div className="vp-input-div">
                <Controller
                  name="contact_phone"
                  control={control}
                  rules={{
                    validate: (value) =>
                      validatePhoneNumber(value, isFieldMandatory),
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      // country={countryCode ? countryCode.toLowerCase() : "th"}
                      defaultCountry={countryCode ? countryCode : "TH"}
                      disabled={!editMode}
                      className="vp-input"
                    />
                  )}
                />
                {errors.contact_phone && (
                  <span className="error-msg">
                    {errors.contact_phone.message}
                  </span>
                )}
              </div>
            ) : (
              <div className="vp-input-div">
                <input
                  disabled={!editMode}
                  type="text"
                  className="vp-input"
                  {...register("contact_phone", {
                    required: isFieldMandatory,
                  })}
                />
              </div>
            )}
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("E-mail address")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="email"
                className="vp-input"
                {...register("contact_email", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.contact_email &&
                errors.contact_email.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.contact_email && (
                <span className="error-msg">
                  {t(
                    "Please enter the contact person email address for account."
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("E-mail address 2")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="email"
                className="vp-input"
                {...register("contact_email2", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.contact_email2 &&
                errors.contact_email2.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.contact_email2 && (
                <span className="error-msg">
                  {t(
                    "Please enter the contact person email2 address for account."
                  )}
                </span>
              )}
            </div>
          </div>
          <h3 className="vendor-profile-contact">
            {t("Email  Contact person for vendor management system (VMS)")}{" "}
          </h3>
          <hr className="contact-line"></hr>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("E-mail address")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="email"
                className="vp-input"
                {...register("email_for_vms", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email_for_vms &&
                errors.email_for_vms.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.email_for_vms && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the value")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("E-mail address 2")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="email"
                className="vp-input"
                {...register("email2_for_vms", {
                  required: isFieldMandatory,
                  maxLength: 80,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email2_for_vms &&
                errors.email2_for_vms.type === "pattern" && (
                  <span className="error-msg">
                    {t("Invalid email address.")}
                  </span>
                )}
              {errors.email2_for_vms && (
                <span className="error-msg">{t("Please enter the value")}</span>
              )}
            </div>
          </div>
          <h3 className="vendor-profile-contact">{t("Registered Address")}</h3>
          <hr className="contact-line"></hr>

          <div className="vp-label-input">
            <label className="vp-label">
              {t("Street/House Number")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="text"
                className="vp-input"
                {...register("street_house_no", {
                  required: isFieldMandatory,
                  maxLength: 60,
                })}
              />
              {errors.street_house_no && (
                <span className="error-msg">{t("Please enter the value")}</span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Country")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("country", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select country")}
                </option>
                {CountryList &&
                  CountryList?.map((country, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        key={index}
                        value={country.country}
                      >
                        {country.country}
                      </option>
                    );
                  })}
              </select>
              {errors.country && (
                <span className="error-msg">
                  {t("Please select the country.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Province/State/City")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>

            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                value={
                  watch("district") ? watch("district") : userInfo?.district
                }
                {...register("district", {
                  required: isFieldMandatory,
                })}
              >
                {stateList.length > 0 &&
                  stateList?.map((e, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        key={index}
                        value={e.state_name ? e.state_name : userInfo.district}
                      >
                        {e.state_name}
                      </option>
                    );
                  })}
              </select>
              {errors.district && (
                <span className="error-msg">
                  {t(
                    "Please enter the district/state/province of registered of the company."
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("District")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                value={watch("city") ? watch("city") : userInfo?.city}
                {...register("city", {
                  required: isFieldMandatory,
                })}
              >
                {/* <option value="">Select City</option> */}
                {cityList.length > 0 &&
                  cityList?.map((e, index) => {
                    return (
                      <option
                        className="dropdown-country"
                        key={index}
                        value={e.city_name}
                      >
                        {e.city_name}
                      </option>
                    );
                  })}
              </select>
              {errors.city && (
                <span className="error-msg">
                  {t("Please enter the city of registered of the company.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input mb-5">
            <label className="vp-label">
              {" "}
              {t("Postal Code")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}
              :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                type="number"
                className="vp-input"
                {...register("postal_code", {
                  required: isFieldMandatory,
                  maxLength: 10,
                  minLength: 5,
                })}
                onWheel={handleOnWheel}
              />
              {errors.postal_code && (
                <span className="error-msg">
                  {t(
                    "Please enter the postal code of registered of the company."
                  )}
                </span>
              )}
            </div>
          </div>
          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
            setDocEdit={setDocEdit}
            commercialTypeCheck={commercialTypeCheck}
            extractedValue={extractedValue}
          />
        </div>
      </form>
    </>
  );
};

export default General;
