import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./jobsfilter.css";

const JobCategory = ({ label, isChecked, onChange }) => (
  <div className="form-check">
    <input
      className="form-check-input"
      type="checkbox"
      id={label}
      value={label}
      checked={isChecked}
      onChange={() => onChange(label.toLowerCase())}
    />
    <label className="form-check-label filter-element-name" htmlFor={label}>
      {label}
    </label>
  </div>
);

const JobsFilter = ({ tableData, onCategoryChange }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { t } = useTranslation();

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategories((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(category)) {
        return prevSelectedCategories.filter((c) => c !== category);
      } else {
        return [...prevSelectedCategories, category];
      }
    });
  }, []);

  useEffect(() => {
    // Convert to lowercase before passing to parent
    onCategoryChange(
      selectedCategories.map((category) => category.toLowerCase())
    );
  }, [selectedCategories, onCategoryChange]); // Specify selectedCategories as a dependency

  const categories = [
    t('Electrical Goods'),
    t('Industrial Goods'),
    t('Mechanical Goods'),
    t('Lab & Chemical Goods'),
    t('Safety & Protection Goods'),
    t('IT Goods'),
    t('Office Goods'),
    t('Piping & Fittings Goods'),
    t('Chemicals'),
    t('Industrial Gas'),
    t('Packaging'),
  ];


  const project = [t("Capex")];

  const services = [
    t("Instrument Service"),
    t("Electrical Service"),
    t("Civil Services"),
    t("Health-Envi Services"),
    t("Inspection Service"),
    t("Mechanical Service"),
    t("Piping Services"),
    t("It Services"),
  ];

  return (
    <div className="" style={{ width: "80%" }}>
      <div className="col-lg-12 col-md-12">
        <div className="filter-card-body mt-3">
          {categories?.map((category, index) => (
            <React.Fragment key={category}>
              <JobCategory
                label={category?.toLowerCase()} // Convert to lowercase here
                isChecked={selectedCategories?.includes(
                  category?.trim()?.toLowerCase()
                )} // Convert to lowercase here
                onChange={handleCategoryChange}
              />
              {(index + 1) % 3 === 0 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="col-lg-12 col-md-12">
        <div className="filter-card-body my-4">
          {project?.map((project, index) => (
            <React.Fragment key={project}>
              <JobCategory
                label={project?.toLowerCase()}
                isChecked={selectedCategories?.includes(
                  project?.trim()?.toLowerCase()
                )} // Convert to lowercase here
                onChange={handleCategoryChange}
              />{" "}
              {/* Convert to lowercase here */}
              {(index + 1) % 3 === 0 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="filter-card-body my-4">
          {services?.map((service, index) => (
            <React.Fragment key={service}>
              <JobCategory
                label={service?.toLowerCase()}
                isChecked={selectedCategories?.includes( 
                  service?.trim()?.toLowerCase()
                )} // Convert to lowercase here
                onChange={handleCategoryChange}
              />{" "}
              {/* Convert to lowercase here */}
              {(index + 1) % 3 === 0 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

JobsFilter.propTypes = {
  tableData: PropTypes.array.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
};

export default JobsFilter;
