import React from "react";
import "./podetails.css";
import PdfIcon from "../../Assets/Images/pdfIcon.png";
import Logo from "../../Assets/Images/companyLogo.svg";
import Name from "../../Assets/Images/companyName.svg";

const PoDetails = () => {
  return (
    <>
      <div className="po-details-main-page p-4">
        <div className="d-flex align-items-center">
          <h3 className="pc6a-extension">PC6A Extension</h3>
          <img src={PdfIcon} alt="" style={{ width: "25px", height: "25px" }} />
        </div>
        <div className="d-flex align-items-center my-2">
          <img src={Logo} alt="" />
          <img src={Name} alt="" className="px-3" />
        </div>
        <div className="d-flex my-4">
          <div className="col-7">
            <div
              className="po-details-top-card p-3"
              style={{ marginRight: "20px" }}
            >
              <h3 className="po-details-top-cards-content p-1">
                VENDOR CODE: TH9L000243 <br />
                C AND T METAL PRODUCTS CO.,LTD <br />
                888 MOO 6 NONGYAT CHONBURI 20190 Thailand
              </h3>
              <h4 className="po-details-top-cards-content p-1">
                E-mail: cntmetal@truemail.co.th <br />
                TEL: 027543335 <span className="px-3">FAX: 027543749</span>{" "}
                <br />
                Attn: MR.PISUDSAK MONGKOLAPIRAK
              </h4>
            </div>
          </div>
          <div className="col-5">
            <div className="po-details-top-card p-3">
              <h3 className="po-details-top-cards-header">SHIP TO ADDRESS</h3>
              <h5 className="po-details-top-cards-content">
                AGC VINYTHAI PUBLIC COMPANY LIMITED <br />
                Phrapradaeng 1 (PPD 1) <br />
                202 Moo 1, Suksawasdi Rd., T.Parkklongbangplakod, A.Prasamutjedi{" "}
                <br />
                Samut Prakarn 10290 Thailand <br />
                TEL.(0) 3892 5000{" "}
                <span className="px-3">FAX.(0) 3868 3048</span>
              </h5>
            </div>
          </div>
        </div>

        <div className="grid-container my-4">
          <div className="item-1">
            <h4 className="po-details-top-cards-header p-2">
              Person in Charge
            </h4>
          </div>
          <div className="item-2">
            <h4 className="po-details-top-cards-content p-2">
              CAPEX Patra <br />
              TEL: <span>FAX:</span>
              <br />
              E-mail: patra.arun@agc.com
            </h4>
          </div>
          <div className="item-3">
            <h4 className="po-details-top-cards-content p-2">P/O 4501426994</h4>
          </div>
          <div className="item-4">
            <h4 className="po-details-top-cards-content p-2">
              DATE: 2022/09/09
            </h4>
          </div>
          <div className="item-5">
            <h4 className="po-details-top-cards-content p-2">Incoterm;</h4>
          </div>
        </div>

        <div className="grid-container my-4">
          <div className="item1">
            <h4 className="po-details-top-cards-header p-2">
              Tax Invoice detail
            </h4>
          </div>
          <div className="item2">
            <h4 className="po-details-top-cards-content p-2">
              AGC VINYTHAI PUBLIC COMPANY LIMITED <br />
              2, I-3 Road, Map Ta Phut Industrial Estate, <br />
              Map Ta Phut Subdistrict, Mueang Rayong District, Rayong 21150
              Thailand <br />
              TAX ID: 0107565000395 Head Office.
            </h4>
          </div>
        </div>
        <div className="grid-container my-4">
          <div className="item1">
            <h4 className="po-details-top-cards-header p-2">Payment Term</h4>
          </div>
          <div className="item2">
            <h4 className="po-details-top-cards-content p-2">
              Local Banking Service 4, Pmt after bllng dt 30 dy
            </h4>
          </div>
        </div>
        <div className="grid-container my-4">
          <div className="item1-1">
            <h4 className="po-details-top-cards-header p-2">Requisitioner</h4>
          </div>
          <div className="item2-2">
            <h4 className="po-details-top-cards-content p-2">
              P/R NO. 5000019919
            </h4>
          </div>
          <div className="item3-3">
            <h4 className="po-details-top-cards-content p-2">TEL.</h4>
          </div>
        </div>
        <div className="po-details-ref my-4 p-2">
          <p className="po-details-top-cards-content">
            Our Ref. <br />
            ODS No.: DS22-4091
          </p>
          <p className="po-details-top-cards-content">
            Your reference : QE2200124 (Excluding Sales Term and Condition){" "}
            <br />
            DATE : 1-SEP-2022
          </p>
          <p className="po-details-top-cards-content">
            PAYMENT SCHEDULE : <br />
            10% OF PO AFTER SUBMIT DRAWING FOR APPROVAL <br />
            80% OF PO AFTER INSTALLATION AND COMPLETE SAT <br />
            10% OF PO AFTER SUBMIT FINAL DOCUMENT
          </p>
        </div>
        <div className="" style={{ background: "white" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <th className="po-details-top-cards-content p-2">
                Delivery date
              </th>
              <th className="po-details-top-cards-content p-2">Qty</th>
              <th className="po-details-top-cards-content p-2">Unit</th>
              <th className="po-details-top-cards-content p-2">Unit Price</th>
              <th className="po-details-top-cards-content p-2">Amount(THB)</th>
            </tr>
            <tr>
              <td className="po-details-top-cards-content p-2">2023/04/06</td>
              <td className="po-details-top-cards-content p-2">1.000</td>
              <td className="po-details-top-cards-content p-2">JOB</td>
              <td className="po-details-top-cards-content p-2">
                842,000 THB <br /> 1/JOB
              </td>
              <td className="po-details-top-cards-content p-2">842,000.00</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="po-details-main-page my-4">
        <div className="d-flex">
          <div className="col-1">
            <p className="po-details-desc py-2" style={{ textAlign: "center" }}>
              No
            </p>
          </div>
          <div className="col-11">
            <p className="po-details-desc py-2">Description</p>
          </div>
        </div>
        <div className="d-flex" style={{ background: "white" }}>
          <div className="col-1">
            <p
              className="po-details-top-cards-content py-3"
              style={{ textAlign: "center" }}
            >
              10
            </p>
          </div>
          <div className="col-11 po-details-top-cards-content">
            <p>
              <h5 className="po-details-top-cards-header py-3">
                PC6A Extension
              </h5>{" "}
              <br />
              <h5 className="po-details-top-cards-header py-1">
                Technical Specification
              </h5>
              - Power Center Brand: SIEMENS
              <br />
              - Model : SIVACON 8PT
              <br />
              <h5 className="po-details-top-cards-header pt-4 pb-2">
                Scope of work:
              </h5>
              - PC6A Extension - Diamension: W1000 x H26000 x D1200 mm.
              <br />
              - Ingress Protection: IP42
              <br />
              - ACB 3P 1250A amount: 1 PC
              <br />
              - ACB model: WL II 1250 N<br />
              - Air Circuit Breaker (ACB): 3 Poles
              <br />
              - ACB Rated Current: 1250 A<br />
              - ACB Rated short circuit: 66 kA
              <br />
              - ACB size: 2<br />
              - ACB Protection Unit: ETU45B (Phase and Ground fault Protection)
              <br />
              - Withdrawable guide frame for ACB size 2 amount: 2 PC
              <br />
              - Digital Power Meter with CT Class 1.0
              <br />
              - Control and metering with wiring
              <br />
              - Metal sheet, Busbad and accessories
              <br />
              - Assembling and Installation at site
              <br />
              - Complete wiring and mounting equipment
              <br />
              - Test Protective Unit ETU45B (Phase and Ground fault Protection)
              <br />
              <h5 className="po-details-top-cards-header pt-4 pb-2">REMARK:</h5>
              - WORK PERFORMS DURING VIHYTHAI SHUTDOWN IN MAY-2023 (TO CONFIRM
              THE DATE 1 MONTH IN ADVANCE IF ANY CHANGED).
              <br />
              - JOB SAFETY ANALYSIS SHALL BE PREPARED WITH VINYTHAI 1 MONTH IN
              ADVANCE.
              <br />
              - CONTRACTOR IS REQUIRED TO KEEP WORKING AREA AT ALL TIMES AND
              DISPOSE ALL WASTE AND SCRAP AFTER FINISH WORK.
              <br />
              - DETAILED INTERVENTION REPORT MUST BE SUBMITTED TO VINYTHAI
              WITHIN 2 WEEKS AFTER FINISH WORK. WE PREFER TO GET THE DRAFT
              REPORT ON THAT DAY AFTER WORK.
              <br />
              - SAFETY INDUCTION TRAINING MUST BE PERFORMED PRIOR TO START WORK
              (3 HOURS).
              <br />
              - MINIMUM PERSONAL PROTECTIVE EQUIPMENT (PPE) (I.E. SAFETY SHOES,
              SAFETY HAT, GOGGLE, LONG SLEEVE WORKING SHIRT, CANISTER WITH HALF
              MASK) ARE STRICTLY REQUIRED UPON ENTRY TO JOB SITE.
              <br />- TPA talk - พูดคุยเกี่ยวกับเรื่องความปลอดภัยในการทำงาน (
              ให้ระบุจุดล้างตาเข้าไปใน PTA พร้อมเซนต์ชื่อทุกคน )
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end py-3">
          <div className="px-3 po-details-top-cards-content">
            <p>Sub Total</p>
            <p>VAT 7.00%</p>
          </div>
          <div className="px-3 po-details-top-cards-content">
            <p>842,000.00 THB</p>
            <p>58,940.00 THB</p>
          </div>
        </div>
        <div className="d-flex justify-content-end po-details-top-cards-header px-3">
          <p>(NINE HUNDRED THOUSAND NINE HUNDRED FORTY THB) </p>
          <p className="px-4">Total Amount</p>
          <p>900,940.00 THB</p>
        </div>
      </div>
      <div className="po-details-main-page p-3">
        <div className="d-flex">
          <div
            className="col-4 po-details-top-cards-content"
            style={{
              borderRight: "1px solid rgba(16, 58, 93, 1)",
              paddingLeft: "20px",
            }}
          >
            <p>Authorized by</p>
            <p>Tatsanawat Khiewphimpha</p>
            <p>2022/09/09</p>
          </div>
          <div
            className="col-4 po-details-top-cards-content"
            style={{
              borderRight: "1px solid rgba(16, 58, 93, 1)",
              paddingLeft: "20px",
            }}
          >
            <p>Authorized by</p>
          </div>
          <div
            className="col-4 po-details-top-cards-content"
            style={{ paddingLeft: "20px" }}
          >
            <p>Authorized by</p>
          </div>
        </div>
        <div>
          <p>
            <li className="po-details-top-cards-content px-3">
              This purchase order has been approved by the authorized person &
              is valid without signature for less than 1 MTHB amount excluding
              vat, & is valid with signature for more than or equal 1 MTHB
              amount excluding vat
            </li>
          </p>
        </div>
      </div>
      <div className="po-details-main-page my-4 p-4">
        <h4 className="po-details-top-cards-header">ACKNOWLEDGEMENT BY</h4>
        <div className="d-flex justify-content-between po-details-top-cards-content">
          <p>NAME</p>
          <p style={{ paddingRight: "500px" }}>DATE</p>
        </div>
        <li className="po-details-top-cards-content">
          Please return the Order Acknowledgement with your signature via e-mail
          or fax within 3 working days
        </li>
      </div>
      <div className="po-details-main-page po-details-top-cards-content my-2 p-4">
        <li>Invoices without a purchase order reference will not be paid.</li>
        <li>
          the instructions, specifications, Payment Term & condition as
          appearing on the purchase order or
        </li>
        <li>
          any attachments are made a part, hereof, which seller agreed by
          acceptance this order.
        </li>
        <li>
          Due to limited space available, VNT-ACTH have no parking area for the
          contractors on this Contract.
        </li>
        <li>This order is complied with documents as per below link</li>
        <li> http://www.agcvinythai.com/en/supply-chain/pcm</li>
      </div>
    </>
  );
};

export default PoDetails;
