import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import "./potable.css";
import JobExpandedTable from "../Jobs/JobsTable/JobExpandedTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import PrDetailsUnderJobs from "../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};

const ExpandedComponent = ({ data }) => (
  <pre>
    <JobExpandedTable data={data.child} />
  </pre>
);

const PoTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("all");
  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const data = [
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "ELECTRICAL GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Confirming PO",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Safety & Protection Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Packaging",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Health-envi services",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Instrument service",
      Qty: "1",
      Buyer: "ot",
      status: "Delivering",
    },
    {
      Deliverydate: "2023-06-04",
      Location: "def",
      PRNo: "mtp2",
      PRTitle: "axasscc",
      PurchaseGroup: "PIPING & FITTINGS GOODS",
      Qty: "3",
      Buyer: "rt",
      status: "Confirming PO",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "pqr",
      PRNo: "mtp15",
      PRTitle: "axasspp",
      PurchaseGroup: "Health-envi services",
      Qty: "4",
      Buyer: "rt",
      status: "Delivered",
    },
  ];

  const columns = [
    {
      name: `${t("Location")}`,
      selector: (row) => row.Location,
      sortable: true,
    },
    {
      name: `${t("PR No")}`,
      selector: (row) => row.PRNo,
      sortable: true,
    },
    {
      name: `${t("PR Title")}`,
      selector: (row) => row.PRTitle,
      sortable: true,
      // width: "15%",
    },
    {
      name: `${t("PO No")}`,
      selector: (row) => row.PRNo,
      sortable: true,
    },
    {
      name: `${t("Delivery date(PO)")}`,

      selector: (row) => row.Deliverydate,
      sortable: true,
    },

    {
      name: `${t("Buyer")}`,
      selector: (row) => row.Buyer,
      sortable: true,
    },
    {
      name: `${t("Status")}`,
      cell: (row) => (
        <button
          className={
            row.status == "Delivered"
              ? "dashboard-main-page-award"
              : row.status == "Confirming PO"
              ? "dashboard-main-page-award status-confirming-po"
              : row.status == "Delivering"
              ? "dashboard-main-page-award status-bidding"
              : ""
          }
        >
          {row.status}
        </button>
      ),
    },
    {
      name: `${t("Action")}`,
      cell: (row) => (
        <>
          {row.status == "Confirming PO" ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ gap: "5px" }}
            >
              {" "}
              <button className="dashboard-main-page-download">Download</button>
              <button
                className="dashboard-main-page-pr-view"
                onClick={onClickUploadPo}
              >
                Upload
              </button>
            </div>
          ) : (
            <button
              className="dashboard-main-page-pr-view"
              onClick={() => {
                handleViewPr(row);
              }}
            >
              View Po
            </button>
          )}
        </>
      ),
    },
  ];

  const onClickUploadPo = () => {
    navigate("/upload-po");
  };
  const handleViewPr = (result) => {
    // setIsModalOpen(true);
    navigate("/po-details");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const filteredDataByType =
    type === "all" ? data : data.filter((item) => item.status === type);

  const paginatedData = filteredDataByType.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filteredDataByType.length),
    total: filteredDataByType.length,
  };

  const headerNames = columns.map((column) => column?.name);

  const csvData = data.map((item) =>
    columns.map((column) => item[column?.name])
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getCountByStatus = (status) => {
    return data.filter((item) => item.status === status).length;
  };

  return (
    <>
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
        />
      )}
      <div className="dahsboard-main-page-tables">
        <Nav activeKey="/home" onSelect={handleTypeSelect}>
          <Nav.Item>
            <Nav.Link
              className={
                type === "all" ? "po-table-nav-active" : "po-table-nav"
              }
              eventKey="all"
            >
              {t("All")}
              <span
                className={
                  type === "all" ? "po-top-badge-active" : "po-top-badge"
                }
                eventKey="all"
              >
                {data?.length}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Confirming PO"
                  ? "po-table-nav-active"
                  : "po-table-nav"
              }
              eventKey="Confirming PO"
            >
              {t("Confirming PO")}
              <span
                className={
                  type === "Confirming PO"
                    ? "po-top-badge-active"
                    : "po-top-badge"
                }
                eventKey="Confirming PO"
              >
                {getCountByStatus("Confirming PO")}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Delivering" ? "po-table-nav-active" : "po-table-nav"
              }
              eventKey="Delivering"
            >
              {t("Delivering")}
              <span
                className={
                  type === "Delivering" ? "po-top-badge-active" : "po-top-badge"
                }
                eventKey="Delivering"
              >
                {getCountByStatus("Delivering")}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Delivered" ? "po-table-nav-active" : "po-table-nav"
              }
              eventKey="Delivered"
            >
              {t("Delivered")}
              <span
                className={
                  type === "Delivered" ? "po-top-badge-active" : "po-top-badge"
                }
                eventKey="Delivered"
              >
                {getCountByStatus("Delivering")}
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <div className="">
              <label className="show-po-table-top" for="">
                {t("Show")}
              </label>
              <select
                className="show-select-options px-2 mx-2"
                id=""
                name=""
                value={itemsPerPage}
                onChange={handleDropdownChange}
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <label className="entries-po-table-top">{t("Entries")}</label>
            </div>
          </div>
          <div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="po_table_data.csv"
            />
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            className="vendor-dashboard-pr-table"
            columns={columns}
            data={paginatedData}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row.child}
            expandableRowExpanded={(row) => row.child}
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <p className="table-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
    </>
  );
};

export default PoTable;
