import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { GET_ALL_PRESS } from "../../ApiEndPoints/ApiEndPoints";

export const getAllPressList = createAsyncThunk("PressList", async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const response = await fetch(`${BaseUrl}${GET_ALL_PRESS}`, requestOptions);
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
});

export const myReducer = createSlice({
  name: "PressList",
  initialState: {
    loading: false,
    PressList: [],
  },
  reducers: {
    updateSelectPressList: (state, action) => {
      state.selectedPressId = action.payload;
    },
  },
  extraReducers: {
    [getAllPressList.pending]: (state) => {
      state.loading = true;
    },
    [getAllPressList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.PressList = payload;
    },
  },
});

export const { updateSelectPressList } = myReducer.actions;

export default myReducer.reducer;
