import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import { Col, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
// import Profile from "../../profile";
import Announce from "../../../Pages/registrationTopNav";
import StepProccessingBar from "../StepProccessingBar";
import Header from "../../../Components/header/index.jsx";
import "./categories.css";
import { useDispatch, useSelector } from "react-redux";
import { updatedDataSecond } from "../../../Store/Reducer/registrationReducer";
import { handleKeyDown, subCategoryOptions } from "../../../Utils/Utils";
import useIsFieldMandatory from "../../../CustomHooks/useIsFieldMandatory ";
import { useTranslation } from "react-i18next";
import dropDownIcon from "../../../Assets/Svg/drop-down-icon.svg";

const Categories = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const isFieldMandatory = useIsFieldMandatory();
  const vendorData = useSelector((state) => state.registration.secondObject);
  const onClickBack = () => {
    navigate("/general-info");
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const initialSelected =
    vendorData?.sub_category?.length > 0
      ? vendorData?.sub_category?.map((value) => ({
          label: value,
          value,
        }))
      : [];

  const [selectedSubCategories, setSelectedSubCategories] =
    useState(initialSelected);
  // Define the maximum number of allowed selections
  const maxAllowedSelections = 3;

  // console.log(initialSelected, "initialSelected");
  // console.log(vendorData?.sub_category, "vendorData.sub_category");
  // console.log(selectedSubCategories, "selectedSub");

  const onChangeSubCategory = (value) => {
    const selectedValues = value.slice(0, maxAllowedSelections);
    setValue("sub_category", selectedValues);
    setSelectedSubCategories(selectedValues);
  };

  const onSubmit = (data) => {
    // Include selected values in the data being dispatched
    data.sub_category = Array.isArray(data?.sub_category)
      ? data.sub_category.map((option) =>
          typeof option === "string" ? option : option?.value
        )
      : data?.sub_category;
    // console.log("on submit @@@@@@@@@@@@@", data.sub_category);
    // if (!showError) {
    dispatch(updatedDataSecond(data));
    navigate("/financial");
    // console.log("*****submit******", data);
  };

  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [vendorData]);

  const ArrowRenderer = ({ expanded }) => (
    <>
      {expanded ? (
        <img src={dropDownIcon} alt="" />
      ) : (
        <img src={dropDownIcon} alt="" />
      )}
    </>
  );

  selectedSubCategories?.some((option) =>
    console.log(option.value === "Other")
  );

  return (
    <>
      {/* <Profile /> */}
      <div className="regstration-header">
        <Header />
      </div>
      <div className="announce-navbar">
        <Announce />
      </div>
      <div className="main-container">
        <StepProccessingBar
          screen1="completed"
          screen2="active"
          screen3="inactive"
          screen4="inactive"
          screen5="inactive"
          screen6="inactive"
          screen7="inactive"
        />
        <div className="vendor-page-container">
          <h1 className="header-registration">{t("Vendor Registration")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <div className="form-container-with-bottomspace">
            <h4 className="header-registration-two">Categories (หมวดหมู่)</h4>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t("Type of Business")}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("type_of_business", {
                    required: isFieldMandatory,
                  })}
                >
                  <option className="dropdown-country" value="" hidden>
                    {" "}
                    {t("select business type")}
                  </option>
                  <option value="Manufacturer"> {t("Manufacturer")}</option>
                  <option value="Service">{t("Service")}</option>
                  <option value="Trading">{t("Trading")}</option>
                  <option value="Transportation">{t("Transportation")}</option>
                  <option value="Rawmaterial">{t("Raw material")}</option>
                  <option value="other">{t("Other, Please specify")}</option>
                </select>
                {errors.type_of_business && (
                  <span className="error-msg">
                    {t("Please select the type of business")}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t("Experience")}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("experience", {
                    required: isFieldMandatory,
                    // maxLength: 80,
                  })}
                >
                  <option className="dropdown-country" value="" hidden>
                    {t("select Experience")}{" "}
                  </option>
                  <option value="Less than">{t("Less than 1 year")} </option>
                  <option value="Up to ">{t("Up to 3 years")}</option>
                  <option value="Over">{t("Over 3 years")}</option>
                </select>
                {errors.experience && (
                  <span className="error-msg">
                    {t("Please select the experience.")}
                  </span>
                )}
              </div>
            </div>
            {watch("type_of_business") === "other" && (
              <div className="textbox-container">
                <div className="left-container-hidden-input">
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("business_specify", {
                      required: true,
                      maxLength: 80,
                    })}
                  />
                  {errors.business_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t("Working Day")}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("working_day", {
                    required: isFieldMandatory,
                  })}
                >
                  <option className="dropdown-country" value="" hidden>
                    {t("select working day")}
                  </option>
                  <option value="1day">{t("1 day")}</option>
                  <option value="2day">{t("2 day")}</option>
                  <option value="3day">{t("3 day")}</option>
                  <option value="4day">{t("4 day")}</option>
                  <option value="5day">{t("5 day")}</option>
                  <option value="6day">{t("6 day")}</option>
                  <option value="7day">{t("7 day")}</option>
                </select>
                {errors.working_day && (
                  <span className="error-msg">
                    {t("Please select the working days.")}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t("Working Time")}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration "
                  {...register("working_time", {
                    required: isFieldMandatory,
                  })}
                >
                  <option className="dropdown-country" value="" hidden>
                    {t("select working time")}
                  </option>
                  <option value="1hour">{t("1 hour")}</option>
                  <option value="3hours">{t("3 hours")}</option>
                  <option value="6hours">{t("6 hours")}</option>
                  <option value="8hours">{t("8 hours")}</option>
                  <option value="10hours">{t("10 hours")}</option>
                  <option value="12hours">{t("12 hours")}</option>
                </select>
                {errors.working_time && (
                  <span className="error-msg">
                    {t("Please select the working Time.")}
                  </span>
                )}
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t("Bidding Category")}<span className="staricon">*</span>
                </label>
                <br />
                <select
                  type="text"
                  className="input-border registration  custom-select"
                  {...register("binding_category", {
                    required: true,
                  })}
                >
                  <option className="dropdown-country" value="" hidden>
                    {t("select bidding category")}
                  </option>
                  <option value="Goods">{t("Goods")}</option>
                  <option value="Services">{t("Services")}</option>
                  <option value="Other Please specify">
                    {t("Other Please specify")}
                  </option>
                </select>
                {errors.binding_category && (
                  <span className="error-msg">
                    {t("Please select the bidding category.")}
                  </span>
                )}
              </div>

              <div className="right-container">
                <label className="vendor-input-label">
                  {t("Sub-Category")} (maximum of select list is 3 items)
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <div className="dropdown-multi">
                  <Controller
                    name="sub_category"
                    control={control}
                    rules={{
                      required: isFieldMandatory
                        ? t("Please select at least one subcategory.")
                        : false,
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <MultiSelect
                          className="multi-select-drop-down mb-3"
                          options={subCategoryOptions}
                          {...field}
                          value={selectedSubCategories}
                          onChange={(value) => {
                            const selectedValues = value.slice(
                              0,
                              maxAllowedSelections
                            );
                            field.onChange(selectedValues);
                            onChangeSubCategory(selectedValues);
                          }}
                          labelledBy={t("select sub category")}
                          hasSelectAll={false}
                          ArrowRenderer={ArrowRenderer}
                        />
                        {fieldState.error && (
                          <span className="error-msg mt-3">
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="textbox-container">
              <div className="textbox-container">
                {watch("binding_category") &&
                  watch("binding_category").includes(
                    "Other Please specify"
                  ) && (
                    <div
                      className="left-container"
                      style={{ marginRight: "10px" }}
                    >
                      <br />

                      <input
                        type="text"
                        className="input-border registration-left "
                        {...register("binding_category_specify", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      {errors.binding_category_specify && (
                        <span className="error-msg">
                          {t("Please enter other specific data")}
                        </span>
                      )}
                    </div>
                  )}
              </div>
              <div className="textbox-container">
                {selectedSubCategories?.some(
                  (option) => option?.value === "Other"
                ) && (
                  <div
                    className="right-container"
                    style={{ marginLeft: "10px" }}
                  >
                    <br />

                    <input
                      type="text"
                      className="input-border registration-left "
                      {...register("sub_category_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.sub_category_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t("Name of your top 3 customers (Reference)")}
                  <span className="staricon">*</span>
                </label>
                <br />

                <input
                  type="text"
                  className="input-border registration"
                  {...register("name_of_top3_customers", {
                    required: true,
                  })}
                ></input>
                {errors.name_of_top3_customers && (
                  <span className="error-msg">
                    {t("Please enter the value")}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t(
                    "Any litigation/arbitration proceedings currently in progress or pending"
                  )}
                  {isFieldMandatory && <span className="staricon">*</span>}{" "}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("process_litigation", {
                    required: isFieldMandatory,
                  })}
                >
                  <option value="" hidden>
                    {" "}
                    {t("select")}
                  </option>
                  <option value="no">{t("No")}</option>
                  <option value="yes">{t("Yes")}</option>
                </select>
                {errors.process_litigation && (
                  <span className="error-msg">
                    {t(
                      "Please select litigation /arbitration prceeding currently in progress or pending"
                    )}
                  </span>
                )}
              </div>
            </div>
            {watch("process_litigation") === "yes" && (
              <div className="textbox-container-specify">
                <div className="right-container-specify">
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("litigation", {
                      required: true,
                      maxLength: 80,
                    })}
                  />

                  {errors.litigation && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="buttons">
            <div className="button-submit-container-back">
              <input
                className="button-submit-back"
                type="submit"
                value={t("Back")}
                onClick={onClickBack}
              />
            </div>
            <div className="button-submit-container">
              <button className="button-submit" type="submit">
                {t("Next")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Categories;
