import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { handleKeyDown } from "../../Utils/Utils";
import {
  getVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { toast } from "react-toastify";

const Supplier = ({ setEditMode, editMode, setPageLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    // getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const { userInfo } = useSelector((state) => state.UserInfoReducer);

  function convertStringToArray(str) {
    // console.log("convertString", str.length);
    if (str && str.length > 0) {
      if (str.includes(",")) {
        return str.split(",");
      } else {
        return [str];
      }
    } else {
      return [str];
    }
  }

  const recongizedAsArray =
    userInfo?.company_certified_to_recognize_center?.includes(",")
      ? userInfo.company_certified_to_recognize_center.split(",")
      : [userInfo.company_certified_to_recognize_center];

  const initialSelected =
    userInfo?.company_certified_to_recognize_center?.length > 0
      ? recongizedAsArray.map((value) => ({
          label: value,
          value,
        }))
      : [];
  const [selected, setSelected] = useState(initialSelected);
  const [isNaSelected, setIsNaSelected] = useState(false);
  const [isOtherFieldSelected, setOtherFieldSelected] = useState(false);

  const options = [
    { label: "ISO 9001", value: "ISO 9001", disabled: isNaSelected },
    { label: "ISO 14001", value: "ISO 14001", disabled: isNaSelected },
    { label: "TS 16949", value: "TS 16949", disabled: isNaSelected },
    {
      label: "HALAL ISO standard",
      value: "HALAL ISO standard",
      disabled: isNaSelected,
    },
    { label: "ISO 45001", value: "ISO 45001", disabled: isNaSelected },
    { label: "IEC 17025*", value: "IEC 17025*", disabled: isNaSelected },
    { label: "ASME U STAMP", value: "ASME U STAMP", disabled: isNaSelected },
    { label: "API", value: "API", disabled: isNaSelected },
    {
      label: "Other…. Please specify",
      value: "Other…. Please specify",
      disabled: isNaSelected,
    },
    { label: "N/A", value: "N/A", disabled: isOtherFieldSelected },
  ];

  useEffect(() => {
    if (userInfo.company_certified_to_recognize_center.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (userInfo.company_certified_to_recognize_center.length > 0) {
      setIsNaSelected(false);
      setOtherFieldSelected(true);
    }
  }, [userInfo.company_certified_to_recognize_center]);

  const onChangerRecognizedStandard = (selectedValues) => {
    setSelected(selectedValues);

    const valuesArray = selectedValues.map((item) => item.value);

    if (valuesArray.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (valuesArray.length == 0) {
      setIsNaSelected(false);
      setOtherFieldSelected(false);
    } else {
      setOtherFieldSelected(true);
      setIsNaSelected(false);
    }
  };

  useEffect(() => {
    if (userInfo) {
      Object.keys(userInfo)?.map((key) => {
        setValue(key, userInfo[key]);
      });
    }
  }, [userInfo]);

  const onSubmit = async (data) => {
    if (selected.length === 0) {
      toast.error(
        "Please select at least one option. in Quality Management System certified to a recognized standard"
      );
      return;
    }

    if (selected.some((option) => option.value === "Other…. Please specify")) {
      if (!data.certified_specify) {
        toast.error("Please specify the certification.");
        return;
      }
    }

    // Include selected values in the data being dispatched
    data.company_certified_to_recognize_center = selected.map(
      (option) => option.value
    );

    setPageLoading(true);

    const formdata = new FormData();

    formdata.append(
      "company_certified_to_recognize_center",
      data.company_certified_to_recognize_center
    );
    formdata.append("laboratory_accreditation", data.laboratory_accreditation);
    formdata.append("certified_specify", data.certified_specify);

    try {
      const isSuccess = await dispatch(updateVendorInfo(formdata));

      if (isSuccess?.data?.status === 200) {
        await dispatch(getVendorInfo());
        setEditMode(false);
        setPageLoading(false);
        toast.success(isSuccess?.data?.message);
      } else {
        setPageLoading(false);
        toast.error(isSuccess?.data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error, "Error");
      toast.error(error.message || "Something went wrong");
      setPageLoading(false);
    }
  };

  return (
    <>
      {/* {loading ? (
        <PageLoader />
      ) : ( */}
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Is your company's Quality Management System certified to a recognized standard?"
              )}
              {editMode && <span className="staricon">*</span>} :
            </label>

            <div className="vp-input-div vp-supplier-multi">
              <div
                className={
                  editMode ? "vp-input" : "vp-input vp-multi-input-div"
                }
                style={{
                  padding: "0px",
                  width: "61%",
                  minWidth: "100%",
                  height: "38px",
                }}
                id={!editMode && "disabled-div"}
              >
                <MultiSelect
                  className={editMode ? "" : "vp-supplier-multi-select"}
                  options={options}
                  value={selected}
                  onChange={onChangerRecognizedStandard}
                  labelledBy="Select"
                  hasSelectAll={false}
                  name="company_certified_to_recognize_center"
                />
              </div>
              {errors.company_certified_to_recognize_center && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}

              <div className="vp-input-div" style={{ display: "contents" }}>
                {selected.some(
                  (option) => option.value === "Other…. Please specify"
                ) && (
                  <>
                    <input
                      type="text"
                      disabled={!editMode}
                      style={{ width: "100%", minWidth: "100% !important" }}
                      className="vp-input mt-3"
                      {...register("certified_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.certified_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="vp-label-input mb-5">
            <label className="vp-label">
              {t(
                "For Laboratory Accreditation, please specify the equipment, calibration item, range and best uncertainty?"
              )}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("laboratory_accreditation", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="Yes">{t("Yes")}</option>
                <option value="No">{t("No")}</option>
              </select>
              {errors.laboratory_accreditation && (
                <span className="error-msg">
                  {t("Please select the correct value")}
                </span>
              )}
            </div>
          </div>

          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
          />
        </div>
      </form>
      {/* )} */}
    </>
  );
};

export default Supplier;
