import React, { useCallback, useState } from "react";
import "./uploadpo.css";
import { useDropzone } from "react-dropzone";
import UploadImage from "../../Assets/Images/Frame878.svg";
import CancelIcon from "../../Assets/Images/closeIcon.svg";
import PDFImage from "../../Assets/Images/Vector-pdf.svg";
import QuestionMarkIcon from "../../Assets/Images/questionmarkIcon.svg";

const UploadPO = (props) => {
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  const files = myFiles.map((file) => (
    <div className="upload-po-document-show p-1 my-2">
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <img
            src={PDFImage}
            alt=""
            style={{ width: "24px", height: "30px" }}
          />
        </div>
        <div className="text-center px-3">
          <p key={file.path}>{file.path}</p>
        </div>
      </div>
      <div onClick={removeFile(file)} style={{ cursor: "pointer" }}>
        <img
          src={CancelIcon}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </div>
  ));

  return (
    <>
      <div className="po-upload-main-comp">
        <div className="bgc-po-style-comp">
          <h3 className="upload-po-sub-header py-3">Upload PO File</h3>
          <img src={QuestionMarkIcon} alt="?" className="que-mark-logo" />
        </div>
        <div className="bgc-po-style-comp">
          <section className="upload-po-file p-3">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ cursor: "pointer" }}
              >
                <img src={UploadImage} alt="" />
              </div>
            </div>
          </section>
        </div>

        <div className="bgc-po-style-comp py-3">
          <button
            type="button"
            className="upload-po-image-btn mt-3 p-2"
            onClick={open}
          >
            Select Document File
          </button>
        </div>
      </div>

      <div className="drop-zone-document-title-div pt-4 mx-3">
        <label className="upload-drop-zone-document-title">
          Document Files
        </label>
      </div>
      <div className="po-document-file-name px-4">{files}</div>
    </>
  );
};

export default UploadPO;
