// vendor side Actions 

const Actions = {
    VENDOR_INFO : 'VENDOR_INFO',
    VENDOR_INFO_FAILURE:"VENDOR_INFO_FAILURE",
    UPDATE_VENDOR_INFO : 'UPDATE_VENDOR_INFO',
    UPDATE_VENDOR_INFO_LOADING: 'UPDATE_VENDOR_INFO_LOADING',
    UPDATE_VENDOR_INFO_FAILURE : 'UPDATE_VENDOR_INFO_FAILURE',
    LATEST_VENDOR_INFO: 'LATEST_VENDOR_INFO',
    CLEAR_VENDOR_INFO: 'CLEAR_VENDOR_INFO',
    CLEAR_LATEST_VENOR_INFO: 'CLEAR_LATEST_VENOR__INFO',
}


module.exports = Actions;