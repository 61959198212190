import React from "react";
import { CSVLink } from "react-csv";
import ImagePdf from "../../Assets/Images/Vector.svg";

const CSVDownloadButton = ({ data, headers, filename }) => {
  // console.log(headers , "headers")
  return (
    <CSVLink data={data} headers={headers} filename={filename}>
      <img src={ImagePdf} alt="download" />
    </CSVLink>
  );
};

export default CSVDownloadButton;
