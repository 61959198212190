import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const userToken = localStorage.getItem("vms_vendor_token");
  // console.log(userToken, "protected token");
  if (userToken) {
    return true;
  } else {
    return false;
  }
};
const ProtectedRoutes = () => {
  const auth = useAuth();
  // console.log(auth,"this after logout button clicked")
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
