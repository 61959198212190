import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const currentPathname = window.location.pathname;

  console.log("currentPathname", currentPathname);
  return (
    <div style={{ textAlign: "center", padding: "3rem" }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for does not exist.</p>
      <img
        src=""
        alt="404 Illustration"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      {!currentPathname.includes("testing") && (
        <p>
          Return to the{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            login page.
          </span>
        </p>
      )}
    </div>
  );
};

export default NotFound;
