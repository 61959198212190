import React, { useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import closeIcon from "../../Assets/Svg/close-icon.svg";
import viewFile from "../../Assets/Images/Group.svg";
import "./PrDetailsUnderJobs.css";
import DropZone from "../../Components/DropZone/DropZone";
import CustomPopup from "../CustomPopup/CustomPopup";
import areIcon from "../../Assets/Svg/areyousure-icon.svg";
import successIcon from "../../Assets/Svg/success-icon.svg";

// CardItem component to represent each card
const CardItem = ({ title, title1, title2, index }) => (
  <div className="pr-combined-detail-card-item">
    <span className="pr-combined-detail-card-title">{title}</span>
    <input type="text" className="pr-combined-detail-card-content" />
    <span className="pr-combined-detail-card-title">{title1}</span>
    <input type="text" className="pr-combined-detail-card-content" />
    <span className="pr-combined-detail-card-title">{title2}</span>
    <textarea className="pr-combined-detail-card-content" />
  </div>
);

const PrDetailsUnderJobs = ({ isOpen, closeModal, formTitle }) => {
  const initialData = [
    {
      title: "Topic",
      title1: "PR Number",
      title2: "Description",
    },
    {
      title: "Topic",
      title1: "PR number",
      title2: "Description",
    },
    {
      title: "Topic",
      title1: "PR number",
      title2: "Description",
    },
    {
      title: "Topic",
      title1: "PR number",
      title2: "Description",
    },
    // ... Add other data items here ...
  ];
  const { t } = useTranslation();
  const [data, setData] = useState(initialData);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closePopup = () => {
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <CustomPopup
          isOpenPopup={showModal}
          closePopup={closePopup}
          title="Are you sure!"
          image={areIcon}
          text={"You want to Apply this Project ?"}
          btn1="Cancel"
          btn2="Confirm"
          successTitle="Done!"
          successText={"Apply Project successfully"}
          successIcon={successIcon}
          btn3="Ok"
          scenarioType="applyProject"
          closePrDetails={closeModal}
        />
      )}
      <div className="d-flex justify-content-center">
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className="pr-details-jobs-user-modal"
          overlayClassName="pr-details-jobs-overlay"
          shouldCloseOnOverlayClick={false}
        >
          <div className="pr-details-jobs-title-div">
            <div className="d-flex">
              {" "}
              <h2 style={{ marginRight: "20px" }}>{formTitle}</h2>
              <div className="pr-details-view-file mx-2 px-1">
                <img
                  className="pr-details-view-file-image "
                  src={viewFile}
                  alt=""
                />
                <h5 className="pr-details-view-file-name px-2 pt-2">
                  view attachment file
                </h5>
              </div>
            </div>

            <img
              src={closeIcon}
              alt=""
              onClick={closeModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <div className="pr-details-pr-title" style={{ width: "100%" }}>
              <div className="pr-details-date-main mb-3">
                <div className="pr-details-bidd-deli-text">
                  <label htmlFor="BiddingEnd">{t("Bidding End")}</label>
                  <br />
                  <input type="date" placeholder="Select a Date" />
                </div>
                <div className="pr-details-bidd-deli-text">
                  <label>{t("Buyer")}</label>
                  <input type="text" placeholder="" />
                </div>
              </div>

              <label htmlFor="PR Title">{t("PR Title")}</label>
              <br />
              <input type="text" id="PR Title" />
            </div>
            <br />
            <div className="pr-details-jobs-main-scroll">
              <div className="pr-details-jobs-main-container">
                <div className="pr-details-jobs-header">
                  {t("PR Combined Detail")}
                </div>
                {data.map((item, index) => (
                  <div className="pr-details-jobs-card-qtn" key={index}>
                    {/* Render the CardItem component with props */}
                    <CardItem {...item} index={index} />
                  </div>
                ))}
              </div>
            </div>

            {/* note */}
            <div className="quotation-pr-title">
              <span className="quotation-pr-note-text">{t("Note")}</span>
              <div className="pr-note-textarea">
                <textarea rows="1" cols="130" />
              </div>
            </div>
            <br />

            <DropZone />

            <div className="pr-details-pr-title" style={{ width: "100%" }}>
              <label htmlFor="Phone Number">{t("Phone Number")}</label>
              <br />
              <input type="text" id="Phone Number" />
            </div>

            <div className="pt-3 create-btns-div">
              <button
                type="button"
                className="pr-details-jobs-submit-btn pr-details-jobs-cancel-btn"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button type="submit" className="pr-details-jobs-submit-btn">
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default PrDetailsUnderJobs;
