import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import registrationReducer from "./Reducer/registrationReducer";
import countryList from "./Reducer/countryList";
import UserInfoReducer from "./Reducer/userInfo_Reducer";
import getAllPressRelease from "./Reducer/getAllPressRelease";

const rootReducers = combineReducers({
  registration: registrationReducer,
  countryList: countryList,
  UserInfoReducer: UserInfoReducer,
  getAllPressRelease: getAllPressRelease,
});
const persistConfig = {
  key: "root",
  storage,
};
// const persistedReducer = persistReducer(persistConfig, reducers);
// export default configureStore({
//   reducer: persistedReducer,
//   middleware: [thunk],
//   devTools: process.env.NODE_ENV !== "production",
// });

const persistedReducer = persistReducer(persistConfig, rootReducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== "production",
});
const persistor = persistStore(store);

export { store, persistor };
