import React from "react";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./dashboardhome.css";
import StatCard from "../../Components/StatCard/Index";
import DashBoardMainPagePoTable from "../../Components/DashboardMainPagePoTable";
import DashBoardMainPagePrTable from "../../Components/DashboardMainPagePrTable";
import ImagePdf from "../../Assets/Images/Vector.svg";
import { useTranslation } from "react-i18next";

const DashBoardHomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <DashBoardLayout>
        <div className="main-dashboard-body mb-3">
          <h1 className="vendor-dashboard-heading py-2 px-2">
            {t("Vendor DashBoard")}
          </h1>
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={t("Performance")}
                  more={"..."}
                  count={"100%"}
                  view={""}
                />
              </div>
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={"PR"}
                  more={"..."}
                  count={"214"}
                  view={"view More"}
                  path={"/pr-table"}
                />
              </div>
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={"PO"}
                  more={"..."}
                  count={"124"}
                  view={"view More"}
                  path={"/po-table"}
                />
              </div>
            </div>
          </div>

          {/* pr table div */}
          <div className="container-fluid mt-2 mb-5">
            <DashBoardMainPagePrTable />
          </div>

          {/* po table div */}
          <div className="container-fluid mt-4 mb-5">
            <DashBoardMainPagePoTable />
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default DashBoardHomePage;
