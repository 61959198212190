import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./dashboardmainpageprtable.css";
import { useTranslation } from "react-i18next";
import JobExpandedTable from "../Jobs/JobsTable/JobExpandedTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import PrDetailsUnderJobs from "../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};

const ExpandedComponent = ({ data }) => (
  <pre>
    <JobExpandedTable data={data.child} />
  </pre>
);

const DashboardPrTable = () => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = [
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "ELECTRICAL GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Declined",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Safety & Protection Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Packaging",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Health-envi services",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Instrument service",
      Qty: "1",
      Buyer: "ot",
      status: "Declined",
    },
    {
      Deliverydate: "2023-06-04",
      Location: "def",
      PRNo: "mtp2",
      PRTitle: "axasscc",
      PurchaseGroup: "PIPING & FITTINGS GOODS",
      Qty: "3",
      Buyer: "rt",
      status: "Declined",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "pqr",
      PRNo: "mtp15",
      PRTitle: "axasspp",
      PurchaseGroup: "Health-envi services",
      Qty: "4",
      Buyer: "rt",
      status: "Bidding",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "stu",
      PRNo: "mtp16",
      PRTitle: "axassqq",
      PurchaseGroup: "It Services",
      Qty: "2",
      Buyer: "ot",
      status: "Bidding",
      child: [
        {
          PurchaseRequistion: "ssss",
          Description: "zxx hose 3/4”, SUS315",
          PurchaseGroup: "It Services",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: null,
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "It Services",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-05",
      Location: "ghi",
      PRNo: "mtp21",
      PRTitle: "axassvv",
      PurchaseGroup: "Piping & Fittings Goods",
      Qty: "3",
      Buyer: "wt",
      status: "Awarded",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Safety & Protection Goods",
      Qty: "1",
      Buyer: "ot",
      status: "Declined",
    },
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "OFFICE GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Declined",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Lab & Chemical Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "aaap",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "INSPECTION SERVICE",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-04",
      Location: "def",
      PRNo: "mtp2",
      PRTitle: "axasscc",
      PurchaseGroup: "INSPECTION SERVICE",
      Qty: "3",
      Buyer: "rt",
      status: "Declined",
    },
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "ELECTRICAL GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Declined",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Safety & Protection Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Packaging",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Health-envi services",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-05",
      Location: "pqr",
      PRNo: "mtp15",
      PRTitle: "axasspp",
      PurchaseGroup: "CAPEX",
      Qty: "4",
      Buyer: "rt",
      status: "Bidding",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "stu",
      PRNo: "mtp16",
      PRTitle: "axassqq",
      PurchaseGroup: "CHEMICALS",
      Qty: "2",
      Buyer: "ot",
      status: "Bidding",
      child: [
        {
          PurchaseRequistion: "ssss",
          Description: "zxx hose 3/4”, SUS315",
          PurchaseGroup: "fdp",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: null,
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "aaap",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "abcd hose 3/4”, SUS315",
          PurchaseGroup: "fdaap",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-05",
      Location: "ghi",
      PRNo: "mtp21",
      PRTitle: "axassvv",
      PurchaseGroup: "Lab & Chemical Goods",
      Qty: "3",
      Buyer: "wt",
      status: "Awarded",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Instrument service",
      Qty: "1",
      Buyer: "ot",
      status: "Declined",
    },
  ];

  const columns = [
    {
      name: `${t("Location")}`,
      selector: (row) => row.Location,
      sortable: true,
    },
    {
      name: `${t("PR No")}`,
      selector: (row) => row.PRNo,
      sortable: true,
    },
    {
      name: `${t("PR Title")}`,
      selector: (row) => row.PRTitle,
      sortable: true,
      width: "10%",
    },
    {
      name: `${t("Purchase Group")}`,
      selector: (row) => row.PurchaseGroup,
      sortable: true,
      width: "15%",
    },
    {
      name: "Date Start",
      selector: (row) => row.Deliverydate,
      sortable: true,
    },
    {
      name: "Date End",
      selector: (row) => row.Deliverydate,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.Buyer,
      // sortable: true,
    },
    {
      name: `${t("Status")}`,
      cell: (row) => (
        <button
          className={
            row.status == "Awarded"
              ? "dashboard-main-page-award"
              : row.status == "Declined"
              ? "dashboard-main-page-award status-declined"
              : row.status == "Bidding"
              ? "dashboard-main-page-award status-bidding"
              : ""
          }
        >
          {row.status}
        </button>
      ),
    },
    {
      name: `${t("Action")}`,
      cell: (row) => (
        <button
          className="dashboard-main-page-pr-view"
          onClick={() => {
            handleViewPr(row);
          }}
        >
          View PR
        </button>
      ),
    },
  ];

  const handleViewPr = (result) => {
    setIsModalOpen(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, data.length),
    total: data.length,
  };

  const headerNames = columns.map((column) => column?.name);

  const csvData = data.map((item) =>
    columns.map((column) => item[column?.name])
  );

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
        />
      )}
      <div className="dahsboard-main-page-tables">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <h5 className="dahsboard-main-page-top-head-name">{t("PR")}</h5>
            <div className="">
              <label className="show-po-table-top" for="">
                {t("Show")}
              </label>
              <select
                className="show-select-options px-2 mx-2"
                id=""
                name=""
                value={itemsPerPage}
                onChange={handleDropdownChange}
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <label className="entries-po-table-top">{t("Entries")}</label>
            </div>
          </div>
          <div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table_data.csv"
            />
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            className="vendor-dashboard-pr-table"
            columns={columns}
            data={paginatedData}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows={true}
            expandableRowDisabled={(row) => !row.child}
            expandableRowExpanded={(row) => row.child}
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="table-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
    </>
  );
};

export default DashboardPrTable;
