// utils
const currencyData = [
  { label: "THB", value: "THB" },
  { label: "USD", value: "USD" },
  { label: "GBP", value: "GBP" },
  { label: "EUR", value: "EUR" },
  { label: "JPY", value: "JPY" },
  { label: "HKD", value: "HKD" },
  { label: "MYR", value: "MYR" },
  { label: "SGD", value: "SGD" },
  { label: "BND", value: "BND" },
  { label: "CNY", value: "CNY" },
  { label: "IDR", value: "IDR" },
  { label: "MMK", value: "MMK" },
  { label: "INR", value: "INR" },
  { label: "KRW", value: "KRW" },
  { label: "LAK", value: "LAK" },
  { label: "PHP", value: "PHP" },
  { label: "TWD", value: "TWD" },
  { label: "NZD", value: "NZD" },
  { label: "CHF", value: "CHF" },
  { label: "DKK", value: "DKK" },
  { label: "NOK", value: "NOK" },
  { label: "SEK", value: "SEK" },
  { label: "CAD", value: "CAD" },
  { label: "RUB", value: "RUB" },
  { label: "VND", value: "VND" },
  { label: "ZAR", value: "ZAR" },
  { label: "AED", value: "AED" },
  { label: "BHD", value: "BHD" },
  { label: "OMR", value: "OMR" },
  { label: "QAR", value: "QAR" },
  { label: "SAR", value: "SAR" },
];

const bankName = [
  {
    label: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand)  002",
    value: "BANGKOK BANK PUBLIC COMPANY LTD. (Thailand) 002",
  },
  {
    label: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand)  004",
    value: "KASIKORNBANK PUBLIC COMPANY LIMITED (Thailand)  004",
  },
  {
    label: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom)  005",
    value: "THE ROYAL BANK OF SCOTLAND N.V. (United Kingdom)  005",
  },
  {
    label: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -006",
    value: "KRUNG THAI BANK PUBLIC COMPANY LTD. (Thailand) -006",
  },
  {
    label:
      "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -008",
    value:
      "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION (United States of America) -008",
  },
  {
    label: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand)-011",
    value: "TMBTHANACHART BANK PUBLIC COMPANY LIMITED (Thailand)-011",
  },
  {
    label: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -014",
    value: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD. (Thailand) -014",
  },
  {
    label: "CITIBANK, N.A. (United States of America) -017",
    value: "CITIBANK, N.A. (United States of America) -017",
  },
  {
    label: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -018",
    value: "SUMITOMO MITSUI BANKING CORPORATION (Japan) -018",
  },
  {
    label: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -020",
    value: "STANDARD CHARTERED BANK (THAI) PUBLIC CO., LTD. (Thailand) -020",
  },
  {
    label: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -022",
    value: "CIMB BANKTHAI PUBLIC COMPANY LIMITED (Thailand) -022",
  },
  {
    label: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -024",
    value: "UNITED OVERSEAS BANK (THAI) PUBLIC CO., LTD. (Thailand) -024",
  },
  {
    label: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -025",
    value: "BANK OF AYUDHAYA PUBLIC COMPANY LTD. (Thailand) -025",
  },
  {
    label:
      "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -026",
    value:
      "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED (Taiwan) -026",
  },
  {
    label:
      "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -027",
    value:
      "BANK OF AMERICA NATIONAL ASSOCIATION (United States of America) -027",
  },
  {
    label: "GOVERNMENT SAVING BANK (Thailand) -030",
    value: "GOVERNMENT SAVING BANK (Thailand) -030",
  },
  {
    label:
      "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED \n (Hong Kong and Shanghai) -031",
    value:
      "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (Hong Kong and Shanghai) -031",
  },
  {
    label: "DEUTSCHE BANK AG. (Germany) -032",
    value: "DEUTSCHE BANK AG. (Germany) -032",
  },
  {
    label: "GOVERNMENT HOUSING BANK (Thailand) -033",
    value: "GOVERNMENT HOUSING BANK (Thailand) -033",
  },
  {
    label:
      "BANK FOR AGRICALTURAL AND AGRICALTURAL CO-OPERATIVES (Thailand) -034",
    value:
      "BANK FOR AGRICALTURAL AND AGRICALTURAL CO-OPERATIVES (Thailand) -034",
  },
  {
    label: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -039",
    value: "MIZUHO BANK, LTD. BANGKOK BRANCH (Japan) -039",
  },
  {
    label: "BNP PARIBAS, BANGKOK BRANCH (France) -045",
    value: "BNP PARIBAS, BANGKOK BRANCH (France) -045",
  },
  {
    label: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052",
    value: "BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED (China) -052",
  },
  {
    label: "ISLAMIC BANK OF THAILAND (Thailand) -066",
    value: "ISLAMIC BANK OF THAILAND (Thailand) -066",
  },
  {
    label: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067",
    value: "TISCO BANK PUBLIC COMPANY LIMITED (Thailand) -067",
  },
  {
    label: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069",
    value: "KIATNAKIN BANK PUBLIC COMPANY LIMITED (Thailand) -069",
  },
  {
    label: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070",
    value: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PCL (China) -070",
  },
  {
    label: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -071",
    value: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (Thailand) -071",
  },
  {
    label: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -073",
    value: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED (Thailand) -073",
  },
  {
    label: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -079",
    value: "ANZ BANK (THAI) PUBLIC CO. LTD. (Australia and New Zealand) -079",
  },
  {
    label: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) - 080",
    value: "SUMITOMO MITSUI TRUST BANK (TH) PCL (Japan) - 080",
  },
  {
    label: "OTHER, please specify. (OTH) - 999",
    value: "OTHER, please specify. (OTH) - 999",
  },
];

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

function convertStringToArray(str) {
  // console.log("convertString", str.length);
  if (str && str.length > 0) {
    if (str.includes(",")) {
      return str.split(",");
    } else {
      return [str];
    }
  } else {
    return [str];
  }
}
const subCategoryOptions = [
  { label: "ELECTRICAL", value: "ELECTRICAL" },
  { label: "INSTRUMENT", value: "INSTRUMENT" },
  { label: "MECHANICAL", value: "MECHANICAL" },
  {
    label: "PIPING & FITTINGS",
    value: "PIPING & FITTINGS",
  },
  {
    label: "INSTRUMENT SERVICE",
    value: "INSTRUMENT SERVICE",
  },
  {
    label: "ELECTRICAL SERVICE",
    value: "ELECTRICAL SERVICE",
  },
  { label: "CIVIL", value: "CIVIL" },
  {
    label: "HEALTH-ENVI SERVICES",
    value: "HEALTH-ENVI SERVICES",
  },
  {
    label: "INSPECTION SERVICE",
    value: "INSPECTION SERVICE",
  },
  {
    label: "MECHANICAL SERVICE",
    value: "MECHANICAL SERVICE",
  },
  {
    label: "PIPING SERVICES",
    value: "PIPING SERVICES",
  },
  {
    label: "LAB&CHEMICAL GOODS",
    value: "LAB&CHEMICAL GOODS",
  },
  { label: "OFFICE GOODS", value: "OFFICE GOODS" },
  {
    label: "SAFETY&PROTECTION GOODS",
    value: "SAFETY&PROTECTION GOODS",
  },
  { label: "IT GOODS", value: "IT GOODS" },
  { label: "CHEMICALS", value: "CHEMICALS" },
  {
    label: "INDUSTRIAL GAS",
    value: "INDUSTRIAL GAS",
  },
  { label: "PACKAGING", value: "PACKAGING" },
  { label: "Other", value: "Other" },
];

const ppeOptions = [
  { label: "Safety shoes", value: "Safety shoes" },
  { label: "Safety helmet", value: "Safety helmet" },
  { label: "Safety Glassed/Goggle", value: "Safety Glassed/Goggle" },
  { label: "Chemical Gloves", value: "Chemical Gloves" },
  { label: "Long sleeves shirt", value: "Long sleeves shirt" },
  { label: "Half Mask & Respirator", value: "Half Mask & Respirator" },
  { label: "Other. please specify", value: "Other. please specify" },
];

const isValidNumber = (value) => /^[+-]?\d*\.?\d*$/.test(value);

const handleOnWheel = (event) => {
  event.currentTarget.blur();
};

const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
module.exports = {
  currencyData,
  bankName,
  convertStringToArray,
  handleKeyDown,
  isValidNumber,
  handleOnWheel,
  subCategoryOptions,
  formatNumberWithCommas,
  ppeOptions,
};
