import React from "react";
import "./podetails.css";
import PoDetails from "../../Components/PoDetails";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import { useNavigate } from "react-router-dom";

const PoDetailsPage = () => {
  const navigate = useNavigate();

  const onClickPoPage = () => {
    navigate("/po-table");
  };

  return (
    <>
      <DashBoardLayout>
        <div className="main-dashboard-body" style={{ marginBottom: "100px" }}>
          <div className="container-fluid my-3">
            <PoDetails />
          </div>
          <div className="d-flex justify-content-end container-fluid my-3">
            <button
              className="po-details-back-btn px-4 py-1"
              onClick={onClickPoPage}
            >
              Back
            </button>
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default PoDetailsPage;
