import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  bankName,
  currencyData,
  handleKeyDown,
  handleOnWheel,
} from "../../Utils/Utils";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorInfo,
  latestGeneralVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { toast } from "react-toastify";
import useIsEditFieldMandatory from "./../../CustomHooks/useIsEditFieldMandatory";

const Financial = ({
  setEditMode,
  editMode,
  getPaymentMethod,
  setDocEdit,
  setType,
  setPageLoading,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const isFieldMandatory = useIsEditFieldMandatory();

  // Custom formatting function to add commas to the revenue value
  const formatRevenue = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, "");

    const [integerPart, decimalPart] = numericValue.split(".");

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    let formattedValue = formattedIntegerPart;

    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart?.slice(0, 2);
    }

    return formattedValue;
  };

  const {
    t,
    // i18n
  } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.UserInfoReducer);
  const paymentMethod = watch("payment_method");
  const oldPaymentMethod = userInfo?.payment_method;
  const commercialRegistrationType = userInfo?.commercial_reg_type;
  const extractedValue = commercialRegistrationType
    ?.toString()
    .match(/\((.*?)\)/)?.[1];

  const checkPaymentMethod = paymentMethod !== oldPaymentMethod;
  const paymentMethodUpdated =
    checkPaymentMethod &&
    !["AVT", "AYUDHAYA"].includes(paymentMethod) &&
    ["L", "T", "P"].includes(extractedValue);

  useEffect(() => {
    if (userInfo) {
      Object.keys(userInfo).forEach((key) => {
        setValue(key, userInfo[key]);
      });
    }
  }, [userInfo]);

  const onSubmit = async (data) => {
    getPaymentMethod(data.payment_method);
    setPageLoading(true);
    var formdata = new FormData();

    formdata.append("annual_revenue", data.annual_revenue);
    formdata.append("name_of_parents", data.name_of_parents);
    formdata.append("bank_account_holder", data.bank_account_holder);
    formdata.append("bank_gaurantee_specify", data.bank_gaurantee_specify);
    formdata.append("account_number", data.account_number);
    formdata.append("currency", data.currency);
    formdata.append("bank_name", data.bank_name);
    formdata.append("payment_method", data.payment_method);
    formdata.append("credit_term", data.credit_term);
    formdata.append("registered_sme", data.registered_sme);
    formdata.append(
      "last_6months_bank_guarantee",
      data.last_6months_bank_guarantee
    );
    formdata.append("credit_term_specify", data.credit_term_specify);
    formdata.append("payment_specify", data.payment_specify);
    formdata.append("bank_specify", data.bank_specify);

    const formDataObject = Object.fromEntries(formdata.entries());

    if (paymentMethodUpdated) {
      setType("Documents");
      dispatch(latestGeneralVendorInfo(formDataObject));
      setDocEdit(true);
      setPageLoading(false);
    } else {
      try {
        const isSuccess = await dispatch(updateVendorInfo(formdata));

        if (isSuccess?.data?.status === 200) {
          await dispatch(getVendorInfo());
          setEditMode(false);
          setPageLoading(false);
          toast.success(isSuccess?.data?.message);
        } else {
          setPageLoading(false);
          toast.error(isSuccess?.data?.message || "Something went wrong");
        }
      } catch (error) {
        console.log(error, "Error");
        toast.error(error.message || "Something went wrong");
        setPageLoading(false);
      }
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;

    setValue("annual_revenue", formatRevenue(value));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Annual Revenue (ข้อมูลล่าสุด)")}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled={!editMode}
                className="vp-input"
                value={watch("annual_revenue")}
                {...register("annual_revenue", {
                  required: true,
                  maxLength: 20,
                  onChange: handleChange,
                })}
              />

              {errors.annual_revenue && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the annual revenue")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Names of parent, Affiliate, and subsidiary companies (if any)"
              )}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <input
                disabled={!editMode}
                className="vp-input"
                {...register("name_of_parents", {
                  required: true,
                })}
              />

              {errors.name_of_parents && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the correct value")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Account name")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <input
                type="text"
                disabled={!editMode}
                className="vp-input"
                {...register("bank_account_holder", {
                  required: isFieldMandatory,
                  maxLength: 30,
                  pattern:
                    /^[A-Za-z\sก-๙เแ่้๊๋า-ู็ิ์ํฺ\[\]{}()\-_.,:;'"!@#$%^&*+=<>?~0-9]+$/,
                })}
              />

              {errors.bank_account_holder && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the Account name")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Bank Account")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <input
                type="number"
                disabled={!editMode}
                className="vp-input"
                {...register("account_number", {
                  required: isFieldMandatory,
                })}
                onWheel={handleOnWheel}
              />

              {errors.account_number && (
                <span className="error-msg">
                  {" "}
                  {t("Please enter the bank account number.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Currency")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                type="text"
                disabled={!editMode}
                className="vp-input"
                {...register("currency", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select Currency")}
                </option>
                {currencyData.map((Currency, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={currencyData.value}
                    >
                      {Currency.label}
                    </option>
                  );
                })}
              </select>
              {errors.currency && (
                <span className="error-msg">
                  {t("Please select the currency.")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Bank Name")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                type="text"
                disabled={!editMode}
                style={{ width: "100%" }}
                className="vp-input"
                {...register("bank_name", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select bank name")}
                </option>
                {bankName.map((Bankname, index) => {
                  return (
                    <option
                      className="dropdown-country"
                      key={index}
                      value={bankName.value}
                    >
                      {Bankname.label}
                    </option>
                  );
                })}
              </select>
              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("bank_name") ===
                  "OTHER, please specify. (OTH) - 999" && (
                  <>
                    <input
                      type="text"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("bank_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.bank_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
              {errors.bank_name && (
                <span className="error-msg">
                  {t("Please select the bank account name..")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Payment Method")}{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("payment_method", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select payment method")}
                </option>
                <option value="Transfer">Transfer</option>
                <option value="Cheque (BANK OF AYUDHAYA , Rayong)">
                  Cheque (BANK OF AYUDHAYA , Rayong){" "}
                </option>
                <option value="Cheque (Citi Bank, BKK)">
                  Cheque (Citi Bank, BKK){" "}
                </option>
                <option value="Cheque at AVT’ s office">
                  Cheque at AVT’ s office{" "}
                </option>
                <option value="Other please specify">
                  Other please specify{" "}
                </option>
              </select>
              {errors.payment_method && (
                <span className="error-msg">
                  {t("Please select the payment type.")}{" "}
                </span>
              )}

              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("payment_method") === "Other please specify" && (
                  <>
                    <input
                      type="text"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("payment_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />

                    {errors.payment_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Credit Term")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("credit_term", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select credit term")}
                </option>
                <option value="30Days">{t("30 Days")} </option>
                <option value="Other">{t("Other please specify")}</option>
              </select>
              {errors.credit_term && (
                <span className="error-msg">
                  {t("Please select the credit term.")}{" "}
                </span>
              )}
              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("credit_term") === "Other" && (
                  <>
                    <input
                      type="text"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("credit_term_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />

                    {errors.credit_term_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Have you ever registering entrepreneurs to operate business as SME?"
              )}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("registered_sme", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="Yes">{t("Yes")}</option>
                <option value="No">{t("No")}</option>
              </select>
              {errors.sme_doc && (
                <span className="error-msg">
                  {t(
                    "Please select whether the business has registered as an SME or not."
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input mb-5">
            <label className="vp-label">
              {t("Have you ever made Bank Guarantee within last 6 months")}?{" "}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("last_6months_bank_guarantee", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="No">{t("No")}</option>
                <option value="Yes">{t("Yes")}</option>
              </select>
              {errors.last_6months_bank_guarantee && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("last_6months_bank_guarantee") === "Yes" && (
                  <>
                    <input
                      type="text"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("bank_gaurantee_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.bank_gaurantee_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
            paymentMethodUpdated={paymentMethodUpdated}
            setDocEdit={setDocEdit}
          />
        </div>
      </form>
    </>
  );
};

export default Financial;
