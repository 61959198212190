// import { Route, Routes } from 'react-router-dom';
import "./App.css";
import HomePage from "./Pages/home";
import ResetContentPage from "./Pages/resetConentpage/resetContentPage";
import ResetPasswordPage from "./Pages/resetPassword";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./Pages/login";
import Profile from "./Pages/profile";
import Reset from "./Pages/reset";
//import ResetConfirmMail from "./Pages/reset/resetconfirmmail";
import Vendor from "./Pages/Registration";
import Categories from "./Pages/Registration/Categories";
import Financial from "./Pages/Registration/FinancialData";
import Supplier from "./Pages/Registration/SuppliersQuantitativeCapability";
import SuppliersQuality from "./Pages/Registration/SuppliersQualityManagement";
import SupplierHSE from "./Pages/Registration/SupplierHSE";
import Documents from "./Pages/Registration/DocumentsSubmission";
import Jobs from "./Pages/jobs";
import PressRelease from "./Pages/press-release";
import VendorGuide from "./Pages/vendorGuide";
import "./i18n";
import ProtectedRoutes from "./ProtectedRouter";
// import DashBoardHomePage from './Pages/DashboardHome';
import DashBoardHomePage from "./Pages/DashboardHome/index";
import VendorFollow from "./Pages/DashboardHome/VendorFollow/index";
import PoTablePage from "./Pages/PoTable/index";
import PrTablePage from "./Pages/PrTable/index";
import UpLoadPoPage from "./Pages/UploadPo/index";
import PoDetailsPage from "./Pages/PoDetails/index";
import OtpVerification from "./Pages/Otp";
import VendorProfile from "./Pages/VendorProfile/index";
import NotFound from "./NotFound";

function App() {
  return (
    <Suspense fallback={null}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/reset-content-page" element={<ResetContentPage />} />
          <Route path="/reset-password-page" element={<ResetPasswordPage />} />
          <Route path="/otp-varification" element={<OtpVerification />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/press-release" element={<PressRelease />} />
          <Route path="/vendor-guide" element={<VendorGuide />} />
          <Route path="/general-info" element={<Vendor />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/financial" element={<Financial />} />
          <Route path="/supplier" element={<Supplier />} />
          <Route path="/quality" element={<SuppliersQuality />} />
          <Route path="/supplierhse" element={<SupplierHSE />} />
          <Route path="/documentsubmission" element={<Documents />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<DashBoardHomePage />} />
            <Route path="/vendor-follow" element={<VendorFollow />} />
            <Route path="/vendor-profile" element={<VendorProfile />} />
            <Route path="/po-table" element={<PoTablePage />} />
            <Route path="/pr-table" element={<PrTablePage />} />
            <Route path="/upload-po" element={<UpLoadPoPage />} />
            <Route path="/po-details" element={<PoDetailsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
