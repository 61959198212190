import React, { useState } from "react";
import VendorFollowTable from "../../../Components/DataTable/VendorFollowTable";
import "./vendorFollow.css";
import DashBoardLayout from "../../../Layouts/DashboardLayout/DashBoardLayout";
import Popup from "reactjs-popup";
import closeButton from "../../../Assets/Images/closeButton.png";
import penaltyButton from "../../../Assets/Images/penaltyButtonLogo.png";
import areYouSure from "../../../Assets/Images/areYouSureLogo.png";
import successLogo from "../../../Assets/Images/successLogo.png";
import { useTranslation } from "react-i18next";
import closeicon from "../../../Assets/Images/closeIcon.svg";
import pdficon from "../../../Assets/Svg/pdf-icon.svg";

const VendorFollow = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  // const [type, setType] = useState("all");
  // const [enableFilter, setEnableFilter] = useState(false);
  // const [show, setShow] = useState(false);
  const [types, setTypes] = useState({
    penaltyClaimLetter: false,
    reasonrejectPenalty: false,
    rejectConfirmation: false,
    rejectSuccess: false,
    uploadFilePenaltyAccept: false,
    acceptConfirmation: false,
    acceptSuccess: false,
  });

  const popupState = {
    penaltyClaimLetter: false,
    reasonrejectPenalty: false,
    rejectConfirmation: false,
    rejectSuccess: false,
    uploadFilePenaltyAccept: false,
    acceptConfirmation: false,
    acceptSuccess: false,
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");

  const handleFileSelect = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".pdf";
    fileInput.multiple = true;
    fileInput.addEventListener("change", (e) => {
      const selected = e.target.files;
      const allowedFormats = ["application/pdf"];
      const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

      if (selected.length > 4) {
        setError("Only 4 files are allowed.");
        return;
      }

      const newSelectedFiles = [];

      for (let i = 0; i < selected.length; i++) {
        const file = selected[i];

        if (!allowedFormats.includes(file.type)) {
          setError("Only PDF files are allowed.");
          return;
        }

        if (file.size > maxSize) {
          setError("Only 20MB files are supported.");
          return;
        }

        newSelectedFiles.push(file);
      }

      // Clear any previous errors
      setError("");
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    });
    fileInput.click();
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  // const handleSubmit = () => {
  //   // Handle the submission of selected files here
  // };

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const handleTypeSelect = (selectedKey) => setType(selectedKey);

  // const toggleFilter = () => {
  //   setEnableFilter(!enableFilter);
  // };
  // const onClickAccept = () => {
  //   navigate("/vendor-follow");
  // };

  return (
    <>
      <DashBoardLayout>
        <div className="main-dashboard-body mb-3">
          <div className="container-fluid">
            <h1 className="vendor-follow-header">{t("Vendor Follow")}</h1>
            <br />
            <div className="containerr">
              <VendorFollowTable
                DelayClicked={() => {
                  setTypes({
                    ...popupState,
                    penaltyClaimLetter: true,
                  });
                }}
              />

              <Popup
                open={types.penaltyClaimLetter}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="confirm">
                  <div className="pr-details-jobs-title-div">
                    <div className="d-flex">
                      {" "}
                      <h3 className="penalty-claim-header-text">
                        {t("Penalty Claim Letter")}
                      </h3>
                      <div className="pr-details-view-file mx-2 px-1">
                        <img src={penaltyButton} alt="" />
                        <h5 className="pr-details-view-file-name px-2 pt-2">
                          {t("Download Penalty")}
                        </h5>
                      </div>
                    </div>
                    <button
                      className="penalty-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //                             onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("To (Company Name)")}
                      </h3>
                      <input
                        className="penalty-input-box"
                        type="text"
                        size={90}
                      ></input>
                    </div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">{t("Title")}</h3>
                      <input
                        className="penalty-input-box"
                        type="text"
                        size={90}
                        placeholder="Refer [POXXXXXX], Delivery date is [delivery date]. However you deliver to us on [GR date] "
                      ></input>
                    </div>

                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Description")}
                      </h3>
                      <textarea
                        className="penalty-input"
                        rows="3"
                        placeholder="Under the delay penalty clause mentionable in General Terms and Condition, You agree to be penalized at the rate of 0.5% of the selling price per day and not exceeding than 10%. Due to you fall to delay delivery total [GR date] -[delivery date]."
                        cols="50"
                        name="comment"
                        form="usrform"
                      ></textarea>
                    </div>

                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Penalty Amount")}
                      </h3>
                      <input className="penalty-input" type="text" size={90} />
                    </div>

                    <div className="penalty-accept-reject">
                      <button
                        onClick={() => {
                          setTypes({
                            ...popupState,
                            reasonrejectPenalty: true,
                          });
                        }}
                        className="reject"
                      >
                        {t("Reject")}
                      </button>

                      <button
                        onClick={() => {
                          setTypes({
                            ...popupState,
                            uploadFilePenaltyAccept: true,
                          });
                        }}
                        className="accept"
                      >
                        {t("Accept")}
                      </button>
                    </div>
                  </div>
                </div>
              </Popup>
              <Popup
                open={types.uploadFilePenaltyAccept}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="accept-penalty">
                  <div className="penalty-claim-header">
                    <h2 className="penalty-claim-header-text">
                      {t("Upload File Penalty Accept")}
                    </h2>
                    <button
                      className="penalty-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>
                  <div className="attach-file-reject">
                    <h4 className="reject-attach-text">{t("Attachment")}</h4>
                    <button
                      className="reject-submit"
                      onClick={handleFileSelect}
                    >
                      {t("Attach File")}
                    </button>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <div className="vendor-follow-attachment-list">
                    {selectedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="vendor-follow-attachment-item"
                      >
                        <img
                          src={pdficon}
                          alt="PDF Icon"
                          className="vendor-follow-pdf-icon"
                        />
                        <div className="ven-foll-fn-fs">
                          <span className="vendor-follow-attac-it-file-name">
                            {file.name}
                          </span>
                          <span className="vendor-follow-attac-it-file-size">
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </span>
                        </div>
                        <img
                          src={closeicon}
                          alt="Close Icon"
                          className="vendor-follow-cancel-icon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div>
                    ))}
                  </div>
                  <p className="reject-ptext">
                    {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                  </p>
                  <hr className="line-in-reject" />
                  <div className="reason-submit">
                    <button
                      className="reject-submit"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                          rejectConfirmation: true,
                        });
                      }}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </Popup>
              <Popup
                open={types.acceptConfirmation}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      className="areyousure-close-button"
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={areYouSure} alt=""></img>
                    <h2 className="penalty-accept-header-text">
                      {t("Are you sure!")}
                    </h2>

                    <p className="reason-reject-ptext">
                      {t("You want to Accept this Penalty ?")}
                    </p>
                    <div className="cancel-confirm-penalty">
                      <button
                        className="reject-cancel"
                        onClick={() => {
                          setTypes({
                            ...popupState,
                          });
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="reject-submit"
                        onClick={() => {
                          setTypes({
                            ...popupState,
                            acceptSuccess: true,
                          });
                        }}
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </Popup>
              <Popup
                open={types.acceptSuccess}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      className="areyousure-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={successLogo} alt=""></img>
                    <h2 className="success-done">Done!</h2>
                    <p className="success-ptext">
                      Accept Pennalty successfully
                    </p>

                    <button
                      className="reject-submit"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                    >
                      {" "}
                      {t("Ok")}
                    </button>
                  </div>
                </div>
              </Popup>
              <Popup
                open={types.reasonrejectPenalty}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="reason-reject-penalty">
                  <div className="penalty-claim-header">
                    <h2 className="penalty-claim-header-text">
                      {t("Reason Of Rejection")}
                    </h2>
                    <button
                      className="penalty-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                  </div>

                  <div>
                    <div className="penalty-labels">
                      <h3 className="penalty-input-label">
                        {t("Description")}
                      </h3>
                      <input className="penalty-input" type="text" size={90} />
                    </div>
                  </div>
                  <div className="attach-file-reject">
                    <h4 className="reject-attach-text">
                      {selectedFiles.length} {t("Attachment")}
                    </h4>
                    <button
                      className="reject-submit"
                      onClick={handleFileSelect}
                    >
                      {t("Attach File")}
                    </button>
                  </div>
                  {error && <p className="error-message">{error}</p>}
                  <div className="vendor-follow-attachment-list">
                    {selectedFiles.map((file, index) => (
                      <div
                        key={index}
                        className="vendor-follow-attachment-item"
                      >
                        <img
                          src={pdficon}
                          alt="PDF Icon"
                          className="vendor-follow-pdf-icon"
                        />
                        <div className="ven-foll-fn-fs">
                          <span className="vendor-follow-attac-it-file-name">
                            {file.name}
                          </span>
                          <span className="vendor-follow-attac-it-file-size">
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </span>
                        </div>
                        <img
                          src={closeicon}
                          alt="Close Icon"
                          className="vendor-follow-cancel-icon"
                          onClick={() => handleRemoveFile(index)}
                        />
                      </div> 
                    ))}
                  </div>
                  <p className="reject-ptext">
                    {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                  </p>
                  <hr className="line-in-reject" />
                  <div className="reason-submit">
                    <button
                      className="reject-submit"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                          rejectConfirmation: true,
                        });
                      }}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              </Popup>

              <Popup
                open={types.rejectConfirmation}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      className="areyousure-close-button"
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={areYouSure} alt=""></img>
                    <h2 className="penalty-claim-header-text">Are you sure!</h2>

                    <p className="reason-reject-ptext">
                      You want to Reason reject this Penalty ?{" "}
                    </p>
                    <div className="cancel-confirm-penalty">
                      <button
                        className="reject-cancel"
                        onClick={() => {
                          setTypes({
                            ...popupState,
                          });
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="reject-submit"
                        onClick={() => {
                          setTypes({
                            ...popupState,
                            rejectSuccess: true,
                          });
                        }}
                      >
                        {t("Confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </Popup>
              <Popup
                open={types.rejectSuccess}
                modal
                nested
                arrow={true}
                position="left center"
                overlayStyle={{
                  backgroundColor: "#999999A1",
                  zIndex: "99999",
                  backdropFilter: "blur(5px)",
                }}
                closeOnDocumentClick={false}
              >
                <div className="areyousure-penalty">
                  <div className="areyousure">
                    <button
                      className="areyousure-close-button"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button>
                    <img src={successLogo} alt=""></img>
                    <h2 className="success-done">Done!</h2>
                    <p className="success-ptext">
                      {t("Reason reject Penalty successfully")}
                    </p>

                    <button
                      className="reject-submit"
                      onClick={() => {
                        setTypes({
                          ...popupState,
                        });
                      }}
                    >
                      {" "}
                      {t("Ok")}
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default VendorFollow;
