import React from "react";
import PrTable from "../../Components/PrTable";
import PrCard from "../../Components/PrTable/PrCard";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./prtable.css";
import { useTranslation } from "react-i18next";

const PrTablePage = () => {
  const { t } = useTranslation();

  return (
    <DashBoardLayout>
      <div className="main-dashboard-body mb-3">
        <h3 className="vendor-dashboard-heading py-2 px-3">{t("PR")}</h3>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("All PR")} more={"..."} count={"414"} />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("Bidding")} more={"..."} count={"214"} />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("Awarded")} more={"..."} count={"100"} />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-2 mb-5">
          <PrTable />
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default PrTablePage;
