import React from "react";
import Header from "../../Components/header";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./reset.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { VENDOR_RESET_PASSWORD } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ResetPasswordHomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onClickJobs = () => {
    navigate("/jobs");
  };

  const onClickVendorGuide = () => {
    navigate("/vendor-guide");
  };

  const onSubmit = (data) => {
    localStorage.setItem("user_name", data.user_name);
    axios
      .post(`${BaseUrl}${VENDOR_RESET_PASSWORD}`, data)
      .then((res) => {
        localStorage.setItem("user_reset_token", res.data.data.token);
        if (res.data.status === 200) {
          toast.success(res.data.message);
          navigate("/otp-varification");
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("onError", err);
      });
  };
  return (
    <div className="mainpage">
      <div className="pt-3">
        <Header />
      </div>
      <div className="loginpage">
        <div className="login" style={{ marginTop: "0px" }}>
          <div className="col-12 col-md-6 col-lg-6 d-none d-sm-block">
            <div className="login-page-left-card">
              {/* <img src="/images/VendorPortal.png" alt=""  className='blur-image'/> */}
              <p className="small">AGC VINYTHAI</p>
              <h1 className="main">
                {t("VENDOR")} <br /> {t("MANAGEMENT SYSTEM")}
              </h1>
              <div className="buttons pt-4">
                <button onClick={onClickVendorGuide} className="vendor-btn ">
                  {t("Vendor Guide")}
                </button>
                <button onClick={onClickJobs} className="job-btn mx-2">
                  {t("Jobs")}
                </button>
              </div>
              <div className="left-card-bottom">
                <div>
                  <CopyrightIcon style={{ width: "12px" }} />
                </div>{" "}
                &nbsp;
                <div>2022 AGC Vinythai. All Right Reserved</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6  col-sm-12">
            <div className="reset-page-right-card">
              <h2 className="mt-5 pt-4 second-heading">
                {t("Reset Password")}
              </h2>
              <p className="reset-sub-content">
                {t("Please input contact person for VMS email")}
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="mb-3" style={{ width: "280px" }}>
                  <label for="#" class="form-labels">
                    <h6 className="small-heading">Email Address</h6>
                  </label>
                  <input
                    {...register("user_name", {
                      required: t("Required*"),
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                    style={{ border: "2px solid #5B6770" }}
                    type="email"
                    class="form-control"
                    id="#"
                  />
                  {errors.user_name && (
                    <span className="error-msg">
                      {errors.user_name.message}
                    </span>
                  )}
                </div>

                <div className="mt-2">
                  <button className="loginbutton">
                    {t("Request Password")}
                  </button>
                </div>
              </form>
              <div
                className="right-card-bottom mt-6"
                style={{ marginTop: "auto", marginBottom: "18px" }}
              >
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span> {t("Terms of Service")} | </span>
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1"> {t("Privacy Policy")} | </span>{" "}
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1">AVT {t("Privacy Policy")}| </span>{" "}
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  <span className="px-1"> {t("Accessibility")} </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordHomePage;
