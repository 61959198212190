import React, { useState, useEffect } from "react";
import axios from "axios";
import CopyrightIcon from "@mui/icons-material/Copyright";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { VENDOR_LOGIN } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import TermsAndConditions from "../termsandconditions";
import showPass from "../../Assets/Images/show-password.png";
import hidePassword from "../../Assets/Images/hide-password.png";
import "./login.css";

const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("vms_vendor_token");
  const [show, setShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClickJobs = () => {
    navigate("/jobs");
  };

  const onClickVendorGuide = () => {
    navigate("/vendor-guide");
  };

  useEffect(() => {
    const cookies = document.cookie.split("; ");
    let storedEmail = "";
    let storedPassword = "";
    let storedRememberMe = false;

    cookies.forEach((cookie) => {
      const [key, value] = cookie.split("=");
      if (key === "email") {
        storedEmail = atob(value); // Decode the Base64 encoded value
      } else if (key === "password") {
        storedPassword = atob(value); // Decode the Base64 encoded value
      } else if (key === "rememberMe") {
        storedRememberMe = value === "true";
      }
    });

    if (storedRememberMe) {
      setValue("user_name", storedEmail);
      setValue("password", storedPassword);
      setRememberMe(true);
    }
  }, [setValue]);

  const secretKey = "mysecretkey12345"; // Replace this with your actual secret key

  const encryptData = (data, key) => {
    try {
      const serializedData = JSON.stringify(data);
      const encryptedData = btoa(encodeURIComponent(serializedData));
      return encryptedData;
    } catch (error) {
      console.error("Encryption error:", error);
      return null;
    }
  };

  const onSubmit = (data) => {
    const { user_name, password } = data;

    if (rememberMe) {
      document.cookie = `email=${btoa(user_name)};path=/`; // Encode the value to Base64
      document.cookie = `password=${btoa(password)};path=/`; // Encode the value to Base64
      document.cookie = `rememberMe=true;path=/`;
    } else {
      document.cookie = "email=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      document.cookie =
        "password=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
      document.cookie =
        "rememberMe=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/";
    }

    axios
      .post(`${BaseUrl}${VENDOR_LOGIN}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          const userInfo = JSON.stringify(res.data.data.user_info);
          const encryptedUser = encryptData(userInfo, secretKey);
          localStorage.setItem("encryptedUser", encryptedUser);
          localStorage.setItem("vms_vendor_token", res.data.data.token);
          // navigate("/jobs");
          window.location = "/jobs";
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
        }
      })
      .catch((errors) => {
        console.log("login error", errors);
      });
  };
  const handleChackBoxChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className="login">
      <TermsAndConditions show={show} handleClose={handleClose} />
      <div className="col-12 col-md-6 col-lg-6 d-none d-sm-block">
        <div className="login-left-card ">
          {/* <img src="/images/VendorPortal.png" alt=""  className='blur-image'/> */}
          <p className="small">AGC VINYTHAI</p>
          <h1 className="main">
            {t("VENDOR")} <br /> {t("MANAGEMENT SYSTEM")}
          </h1>
          <div className="buttons pt-4">
            <button onClick={onClickVendorGuide} className="vendor-btn ">
              {t("Vendor Guide")}
            </button>
            <button onClick={onClickJobs} className="job-btn mx-2">
              {t("Jobs")}
            </button>
          </div>
          <div className="left-card-bottom">
            <div>
              <CopyrightIcon style={{ width: "12px" }} />
            </div>{" "}
            &nbsp;
            <div className="pt-1">2022 AGC Vinythai. All Right Reserved</div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6 col-lg-6  col-sm-12">
        <div className="login-right-card ">
          <h2 className="pt-2 second-heading">{t("Vendor Login")}</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2" style={{ width: "280px" }}>
              <label for="#" className="form-labels">
                {" "}
                <h6 className="small-heading">{t("Username")}</h6>
              </label>
              <input
                {...register("user_name", {
                  required: t("Required*"),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("Invalid email address."),
                  },
                })}
                style={{ border: "2px solid #5B6770" }}
                type="text"
                className="form-control"
                id="#"
                autoComplete="off"
              />
              {errors.user_name && (
                <span className="error-msg">{errors.user_name.message}</span>
              )}
            </div>
            <div className="mb-2" style={{ width: "280px" }}>
              <label for="#" className="form-labels">
                <h6 className="small-heading">{t("Password")}</h6>
              </label>
              <div
                style={{ border: "2px solid #5B6770", width: "100%" }}
                className="d-flex justify-content-between form-control mb-4"
              >
                <input
                  {...register("password", {
                    required: t("Required*"),
                  })}
                  style={{ width: "100%" }}
                  type={showPassword ? "text" : "password"}
                  id="#"
                  autoComplete="off"
                />
                <button
                  style={{ backgroundColor: "white" }}
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <img
                    src={showPassword ? hidePassword : showPass}
                    style={{ width: "20px" }}
                  />
                </button>
              </div>
              {errors.password && (
                <span className="error-msg">{errors.password.message}</span>
              )}
            </div>
            <div className="">
              <input
                onChange={handleChackBoxChange}
                className="form-check-input"
                type="checkbox"
                value=""
                id="#"
              />
              <label className="form-check-label" for="#">
                <h6 className="small-heading">{t("Remember me")}</h6>
              </label>
            </div>
            <div className="py-1">
              <button className="loginbutton">{t("Login")}</button>
            </div>
          </form>
          <div className="pt-1">
            {!token ? (
              <h5 className="not-reg-yet" style={{ fontSize: "16px" }}>
                {t("Not Registered yet")}?{" "}
                <span
                  onClick={handleShow}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  {t("Register")}
                </span>
              </h5>
            ) : null}
            <h5
              className="not-reg-yet"
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => navigate("/reset-password")}
            >
              {t("Forgot Password")} ?
            </h5>
          </div>
          <div
            className="right-card-bottom"
            style={{
              marginTop: "auto",
              marginBottom: "18px",
            }}
          >
            <a href="https://agcvinythai.com/pdpa/" target="blank">
              {" "}
              <span> {t("Terms of Service")} | </span>
            </a>
            <a href="https://agcvinythai.com/pdpa/" target="blank">
              {" "}
              <span className="px-1">{t("Privacy Policy")} | </span>{" "}
            </a>
            <a href="https://agcvinythai.com/pdpa/" target="blank">
              {" "}
              <span className="px-1">AVT Privacy Policy | </span>{" "}
            </a>
            <a href="https://agcvinythai.com/pdpa/" target="blank">
              <span className="px-1"> {t("Accessibility")} </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
