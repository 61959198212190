import React from "react";
import "./jobstable.css";

const JobExpandedTable = ({ data }) => {
  // console.log(data, "JobExpandedTable");
  return (
    data && (
      <div className="jobs-expanded-table-div">
        <table className="jobs-expanded-table">
          <thead>
            <tr>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
              <th>Purchase Requisition</th>
              <th>Description</th>
              <th>Purchase Group</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.location}</td>
                  <td>{row?.PurchaseRequistion}</td>
                  <td>{row?.Description}</td>
                  <td>{row?.PurchaseGroup}</td>
                  <td>{row?.item}</td>
                  <td>{row?.Quantity}</td>
                  <td>{row?.Unit}</td>
                  <td>{row?.location}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default JobExpandedTable;
