import React, { useState, useEffect } from "react";
import Login from "../../Components/Login/Login";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import Announce from "../registrationTopNav";
import News from "../../Components/News";
import "./press-relese.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../../Components/header";
import agcN from "../../Assets/Svg/agcN.svg";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import { useTranslation } from "react-i18next";
import { getAllPressList } from "../../Store/Reducer/getAllPressRelease";
import { useDispatch, useSelector } from "react-redux";

const PressRelease = () => {
  const token = localStorage.getItem("vms_vendor_token");
  const { PressList, loading } = useSelector(
    (state) => state.getAllPressRelease
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [enableSee, setEnableSee] = useState(true);
  const itemsPerPage = enableSee ? 3 : 5;
  const [searchValue, setSearchValue] = useState("");
  const [filteredNews, setFilteredNews] = useState([]);
  const { t } = useTranslation();
  const releasedItems =
    Array.isArray(PressList) &&
    PressList?.filter((item) => item?.press_status === "Released");

  // const [news, setNews] = useState([
  //   {
  //     id: 1,
  //     image: agcN,
  //     title: "test 1 one ",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "24-APR-2021",
  //   },
  //   {
  //     id: 2,
  //     image: agcN,
  //     title: "test 2 two",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "23-FEB-2021",
  //   },
  //   {
  //     id: 3,
  //     image: agcN,
  //     title: "test 3 three",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "24-APR-2020",
  //   },
  //   {
  //     id: 4,
  //     image: agcN,
  //     title:
  //       "Ipsum ut four lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "25-APR-2022",
  //   },
  //   {
  //     id: 5,
  //     image: agcN,
  //     title:
  //       "Ipsum ut six five lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "04-APR-2020",
  //   },
  //   {
  //     id: 6,
  //     image: agcN,
  //     title:
  //       "Ipsum ut six five lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "04-APR-2020",
  //   },
  //   {
  //     id: 7,
  //     image: agcN,
  //     title:
  //       "Ipsum ut six five lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem apple Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "04-APR-2020",
  //   },
  //   {
  //     id: 8,
  //     image: agcN,
  //     title:
  //       "Ipsum ut six five lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "04-APR-2020",
  //   },
  //   {
  //     id: 9,
  //     image: agcN,
  //     title:
  //       "Ipsum ut six five lectus fusce semper id sapien quam a maecenas massa urna ac convallis in laoreet potenti placerat risus platea",
  //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummytextever since the 1500s, when an unknown printer took a galley of type and scrambled it to
  //           maketype specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  //     date: "04-APR-2020",
  //   },
  // ]);

  console.log(PressList, loading, "news");
  const handleBack = () => {
    setEnableSee(!enableSee);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchValue(searchTerm);

    const filteredArticles = releasedItems.filter((article) =>
      article.title.toLowerCase().includes(searchTerm)
    );

    setFilteredNews(filteredArticles);

    // Reset currentPage to 1 when searching
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  let displayedNews = releasedItems;

  if (searchValue) {
    displayedNews = filteredNews;
  }

  displayedNews = displayedNews.slice(startIndex, endIndex);

  // Calculate the total number of entries and the current range being displayed
  const totalEntries = releasedItems.length;
  const startEntry = startIndex + 1;
  const endEntry = Math.min(startIndex + itemsPerPage, totalEntries);

  useEffect(() => {
    dispatch(getAllPressList());
  }, []);

  return (
    <>
      {!token ? (
        <div>
          <DefaultLayout>
            <div className="loginpage">
              <Login />
            </div>
          </DefaultLayout>
        </div>
      ) : (
        <div>
          <div className="resetcontentpage">
            <Header />
          </div>
        </div>
      )}

      <div className="">
        <div className="announce-navbar">
          <Announce />
        </div>
        <div className="announcement-page-background ">
          <div className="announcement-jobs-top">
            <h1 className="announcement-jobs-top-header py-2">{t("News")}</h1>

            <input
              type="search"
              placeholder="Search News"
              className="announcement-jobs-top-search py-2 px-3"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      {displayedNews?.length > 0 ? (
        <>
          <div className="container mt-5">
            <div className="row">
              <div className="col-12">
                <div className="announcement-news-top">
                  <div>
                    <h1 className="announcement-news-update">
                      {t("Update News")}
                    </h1>
                  </div>
                  <div onClick={handleBack} style={{ cursor: "pointer" }}>
                    {enableSee ? (
                      <h1 className="announcement-news-seeall">
                        See All
                        <span>
                          <ArrowForwardIcon />
                        </span>
                      </h1>
                    ) : (
                      <h1 className="announcement-news-seeall">
                        Back
                        <span>
                          <ArrowBackIcon />
                        </span>
                      </h1>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="news-page-body">
            <div className="container">
              <div className="row">
                {displayedNews?.map((article, index) => (
                  <div className="col-12" key={index}>
                    <News {...article} />
                  </div>
                ))}
                {!enableSee && displayedNews?.length >= 1 && (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "90%" }}
                  >
                    {" "}
                    <p className="press-entries col-5">
                      Showing {startEntry} to {endEntry} of {totalEntries}{" "}
                      entries
                    </p>
                    <div className="">
                      <CustomPagination
                        totalItems={totalEntries}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <h1 className="d-flex justify-content-center align-items-center py-5">
          No News Available
        </h1>
      )}
    </>
  );
};

export default PressRelease;
