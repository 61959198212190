import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Announce from "../../registrationTopNav";
import After from "../../AfterSubmit";
import StepProccessingBar from "../StepProccessingBar";
import Header from "../../../Components/header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../ApiBaseUrl/ApiBaseUrl";
import { VENDOR_REGISTER } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import PageLoader from "../../../Models/Pageloader/PageLoader";
import { handleKeyDown } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";

const Documents = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const vendorData = useSelector((state) => state.registration);
  const generalInfoData = useSelector(
    (state) => state.registration.firstObject
  );

  // console.log(vendorData, "Vendor data: ");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const commercialRegType =
    generalInfoData && generalInfoData.commercial_reg_type;
  const extractedValue =
    commercialRegType && commercialRegType.toString().match(/\((.*?)\)/)?.[1];
  const isDisable = extractedValue && ["P"].includes(extractedValue);
  const isDisableCertificate = extractedValue && ["L"].includes(extractedValue);
  const isDisableAnnualRevenue =
    extractedValue && ["P", "O", "U"].includes(extractedValue);
  const isDisableDealer = extractedValue && ["L"].includes(extractedValue);

  const [file, setFile] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [fileSizeError, setFileSizeError] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const paymentMethod = vendorData.thirdObject.payment_method;

    let requiredFiles = [];

    if (extractedValue === "L" || extractedValue === "T") {
      requiredFiles.push("company_affidavit_doc", "vat_registration_doc");
    }

    if (
      (extractedValue === "L" ||
        extractedValue === "T" ||
        extractedValue === "P") &&
      paymentMethod !== "AVT" &&
      paymentMethod !== "AYUDHAYA"
    ) {
      requiredFiles.push("bank_info_doc");
    }

    if (extractedValue === "P") {
      requiredFiles.push("copy_id_card");
    }

    if (extractedValue === "P" || extractedValue === "T") {
      requiredFiles.push("certification_doc");
    }

    const uploadedFiles = Object.keys(file);

    const allFilesUploaded = requiredFiles.every((file) =>
      uploadedFiles.includes(file)
    );

    if (allFilesUploaded) {
      setPageLoading(true);
      const objectsToAppend = [
        vendorData.firstObject,
        vendorData.secondObject,
        vendorData.thirdObject,
        vendorData.fourthObject,
        vendorData.fifthObject,
        vendorData.sixthObject,
        // Add more objects here if needed
      ];

      // console.log(fourthObject, "fourth obje");
      var formdata = new FormData();

      const Enums = [
        "process_litigation",
        "registered_sme",
        "last_6months_bank_guarantee",
        "company_limit_size_project",
        "analyses_process_for_late_delivery",
        "laboratory_accreditation",
        "qhse_management",
        "injury_deaths",
        "registered_foreigner_labor",
        "lower18_labor",
      ];
      //

      objectsToAppend.forEach((data) => {
        Object.keys(data).forEach((key) => {
          if (
            !(
              Enums?.includes(key) &&
              (data[key] == undefined || data[key] == null || data[key] == "")
            )
          ) {
            formdata.append(key, data[key] !== undefined ? data[key] : "");
          }
        });
      });

      formdata.append("company_affidavit_doc", file.company_affidavit_doc);
      formdata.append("vat_registration_doc", file.vat_registration_doc);
      formdata.append("bank_info_doc", file.bank_info_doc);
      formdata.append("annual_revenue_doc", file.annual_revenue_doc);
      formdata.append("financial_statement_doc", file.financial_statement_doc);
      formdata.append("memorandum_doc", file.memorandum_doc);
      formdata.append("company_profile_doc", file.company_profile_doc);
      formdata.append("autherize_dealer_doc", file.autherize_dealer_doc);
      formdata.append("certification_doc", file.certification_doc);
      formdata.append("business_license", file.business_license);
      formdata.append("tax_registration", file.tax_registration);
      formdata.append(
        "certificate_of_incoporation",
        file.certificate_of_incoporation
      );
      formdata.append("copy_id_card", file.copy_id_card);
      formdata.append("project_reference_doc", file.project_reference_doc);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseUrl}${VENDOR_REGISTER}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 200) {
            setPageLoading(false);
            setShow(true);
          } else if (result.status === 401) {
            toast.error(result.message);
            setPageLoading(false);
          } else {
            toast.error("Something went wrong");
            setPageLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      toast.error("Please Upload mandatory files and try again");
    }
  };

  const onClickBack = () => {
    navigate("/supplierhse");
  };
  const handleChange = (e) => {
    // let url =
    //         URL.createObjectURL(e.target.files[0]) + "+" + e.target.files[0].name;
    const fileSizeNumber = e.target.files[0].size / 1024 / 1024;
    if (fileSizeNumber < 20) {
      setFile((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0],
      }));
      setFileNames((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0].name,
      }));
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "",
      }));
    } else {
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "Please enter the file below 20MB size.",
      }));
    }
  };

  return (
    <>
      {pageLoading && <PageLoader />}

      <div className="regstration-header">
        <Header />
      </div>
      <div className="announce-navbar">
        <Announce />
      </div>

      <div className="main-container">
        <StepProccessingBar
          screen1="completed"
          screen2="completed"
          screen3="completed"
          screen4="completed"
          screen5="completed"
          screen6="completed"
          screen7="active"
        />
        <div className="vendor-page-container">
          <h1 className="header-registration">{t("Vendor Registration")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <div className="form-container-with-bottomspace-documents">
            <h4 className="header-registration-two">
              Documents Submission (ยื่นเอกสาร)
            </h4>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  1.
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? "Business license or Excise Document"
                    : "Company Affidavit not over 6 months (หนังสือรับรอง ไม่เกิน6 เดือน)"}
                  {(extractedValue === "L" || extractedValue === "T") && (
                    <span className="staricon">*</span>
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <label className="input-border-documents">
                    {extractedValue === "O" ||
                    extractedValue === "U" ||
                    extractedValue === "A"
                      ? fileNames.business_license
                      : fileNames.company_affidavit_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisable
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisable ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name={
                          extractedValue === "O" ||
                          extractedValue === "U" ||
                          extractedValue === "A"
                            ? "business_license"
                            : "company_affidavit_doc"
                        }
                        accept="application/pdf"
                        disabled={isDisable}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? fileSizeError.business_license
                    : fileSizeError.company_affidavit_doc}
                </p>

                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  2.{" "}
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? "Tax Registration"
                    : t("VAT Registration Certificate(ภ.พ.20)")}
                  {(extractedValue === "L" || extractedValue === "T") && (
                    <span className="staricon">*</span>
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {extractedValue === "O" ||
                    extractedValue === "U" ||
                    extractedValue === "A"
                      ? fileNames.tax_registration
                      : fileNames.vat_registration_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisable
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisable ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name={
                          extractedValue === "O" ||
                          extractedValue === "U" ||
                          extractedValue === "A"
                            ? "tax_registration"
                            : "vat_registration_doc"
                        }
                        accept="application/pdf"
                        disabled={isDisable}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? fileSizeError.tax_registration
                    : fileSizeError.vat_registration_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("3.Book Bank or Bank information")}
                  {(extractedValue === "L" ||
                    extractedValue === "T" ||
                    extractedValue === "P") && (
                    <span className="staricon">
                      {" "}
                      {vendorData.thirdObject.payment_method !== "AVT" &&
                      vendorData.thirdObject.payment_method !== "AYUDHAYA"
                        ? "*"
                        : ""}
                    </span>
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.bank_info_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className={
                        vendorData.thirdObject.payment_method === "AVT" ||
                        vendorData.thirdObject.payment_method === "AYUDHAYA"
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={
                        vendorData.thirdObject.payment_method === "AVT"
                          ? ""
                          : handleChange
                      }
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="bank_info_doc"
                        accept="application/pdf"
                        disabled={
                          vendorData.thirdObject.payment_method === "AVT" ||
                          vendorData.thirdObject.payment_method === "AYUDHAYA"
                        }
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.bank_info_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("4.Three Year Annual Revenue")}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.annual_revenue_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisableAnnualRevenue
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisableAnnualRevenue ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="annual_revenue_doc"
                        accept="application/pdf"
                        disabled={isDisableAnnualRevenue}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.annual_revenue_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("5.Three Year Financial Statements")}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.financial_statement_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisableAnnualRevenue
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisableAnnualRevenue ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="financial_statement_doc"
                        accept="application/pdf"
                        disabled={isDisableAnnualRevenue}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {fileSizeError.financial_statement_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  6.
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? "Certificate of incorporation"
                    : "Memorandum of association (หนังสือบริคณห์สนธิ)"}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {extractedValue === "O" ||
                    extractedValue === "U" ||
                    extractedValue === "A"
                      ? fileNames.certificate_of_incoporation
                      : fileNames.memorandum_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisable
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisable ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name={
                          extractedValue === "O" ||
                          extractedValue === "U" ||
                          extractedValue === "A"
                            ? "certificate_of_incoporation"
                            : "memorandum_doc"
                        }
                        accept="application/pdf"
                        disabled={isDisable}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {" "}
                  {extractedValue === "O" ||
                  extractedValue === "U" ||
                  extractedValue === "A"
                    ? fileSizeError.certificate_of_incoporation
                    : fileSizeError.memorandum_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  7.
                  {extractedValue === "P"
                    ? "Copy of ID Card / Copy of house Registration"
                    : t("Company profile / Product line")}
                  {extractedValue === "P" && (
                    <span className="staricon">*</span>
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {extractedValue === "P"
                      ? fileNames.copy_id_card
                      : fileNames.company_profile_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name={
                          extractedValue === "P"
                            ? "copy_id_card"
                            : "company_profile_doc"
                        }
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {" "}
                  {extractedValue === "P"
                    ? fileSizeError.copy_id_card
                    : fileSizeError.company_profile_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("8.Autherize dealer if any")}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.autherize_dealer_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisableDealer
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisableDealer ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="autherize_dealer_doc"
                        accept="application/pdf"
                        disabled={isDisableDealer}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {fileSizeError.autherize_dealer_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("9.Certificate document (for example ISO 9001 or any)")}
                  {(extractedValue === "P" || extractedValue === "T") && (
                    <span className="staricon">*</span>
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.certification_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className={
                        isDisableCertificate
                          ? "uploadFile-document-disabled"
                          : "uploadFile-document"
                      }
                      onChange={isDisableCertificate ? "" : handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="certification_doc"
                        accept="application/pdf"
                        disabled={isDisableCertificate}
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.certification_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("10.Project reference")}
                </label>
                <br />
                <div className="button-input-container">
                  <label for="files" className="input-border-documents">
                    {fileNames.project_reference_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="project_reference_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {fileSizeError.project_reference_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="button-input-container privacy-notice">
                <input
                  type="checkbox"
                  //   className="input-border registration"
                  {...register("privacyNotice", {
                    required: true,
                  })}
                />{" "}
                &nbsp;
                <label className="vendor-input-label-terms d-flex">
                  {t(
                    "I agree to the Terms and Conditions and Read information of"
                  )}
                  &nbsp;
                  <a
                    className="Privacy-notice"
                    href="https://agcvinythai.com/pdpa/"
                    target="_blank"
                  >
                    {t("Privacy Notice")}
                  </a>
                </label>
              </div>
            </div>
            {errors.privacyNotice && (
              <span className="error-msg">
                {t("Please select the check box")}
              </span>
            )}
          </div>
          <div className="buttons">
            <div className="button-submit-container-back">
              <input
                className="button-submit-back"
                type="button"
                value={t("Back")}
                onClick={onClickBack}
              />
            </div>
            <div className="button-submit-container">
              <input
                className="button-submit"
                type="submit"
                value={t("Submit")}
              />
              <After
                isShowSubmit={show}
                show={show}
                handleClose={handleClose}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Documents;
