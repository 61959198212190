import React, {
  useState,
  // useEffect
} from "react";
// import { useTranslation } from "react-i18next";
import pngIcon from "../../Assets/Images/png-icon.png";
import VpFiles from "./VpFiles/VpFiles";
import { useForm } from "react-hook-form";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLatestVendorInfo,
  getVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { toast } from "react-toastify";
import { handleKeyDown } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";

const VpDocuments = ({
  editMode,
  setEditMode,
  setPageLoading,
  // data,
  setDocEdit,
  docEdit,
  commercialRegistrationType,
  paymentMethod,
}) => {
  const {
    // register,
    handleSubmit,
    // getValues,
    // setValue,
    // watch,
    reset,
    formState: { errors },
  } = useForm();

  const { userInfo, generalVendorInfo } = useSelector(
    (state) => state.UserInfoReducer
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = useState({});

  const getPaymentMethod = paymentMethod
    ? paymentMethod
    : userInfo?.payment_method;

  const commercialRegType = userInfo?.commercial_reg_type;

  const extractedValue = commercialRegistrationType
    ? commercialRegistrationType.toString().match(/\((.*?)\)/)?.[1]
    : commercialRegType?.toString().match(/\((.*?)\)/)?.[1];

  const valdiateBookBank =
    !["AVT", "AYUDHAYA"].includes(getPaymentMethod) &&
    ["L", "T", "P"].includes(extractedValue);

  function isNullOrEmpty(value) {
    return value === "" || value === null;
  }

  const onSubmit = async (data) => {
    let requiredFiles = [];

    if (
      (extractedValue === "L" || extractedValue === "T") &&
      isNullOrEmpty(userInfo.company_affidavit_doc)
    ) {
      requiredFiles.push("company_affidavit_doc");
    }
    if (
      (extractedValue === "L" || extractedValue === "T") &&
      isNullOrEmpty(userInfo.vat_registration_doc)
    ) {
      requiredFiles.push("vat_registration_doc");
    }

    if (valdiateBookBank && isNullOrEmpty(userInfo.bank_info_doc)) {
      requiredFiles.push("bank_info_doc");
    }

    if (extractedValue === "P" && isNullOrEmpty(userInfo.copy_id_card)) {
      requiredFiles.push("copy_id_card");
    }

    if (
      (extractedValue === "P" || extractedValue === "T") &&
      isNullOrEmpty(userInfo.certification_doc)
    ) {
      requiredFiles.push("certification_doc");
    }

    const files = Object.keys(uploadedFiles);

    const allFilesUploaded = requiredFiles.every((file) =>
      files.includes(file)
    );

    if (allFilesUploaded) {
      // do api call here
      setPageLoading(true);
      function isFile(value) {
        return value instanceof File;
      }

      const formdata = new FormData();

      if (generalVendorInfo && generalVendorInfo != null) {
        Object.entries(generalVendorInfo).forEach(([key, value]) => {
          formdata.append(key, value);
        });
      }
      formdata.append(
        "company_affidavit_doc",
        uploadedFiles.company_affidavit_doc
          ? uploadedFiles.company_affidavit_doc
          : ""
      );
      formdata.append(
        "vat_registration_doc",
        uploadedFiles.vat_registration_doc
          ? uploadedFiles.vat_registration_doc
          : ""
      );
      formdata.append(
        "bank_info_doc",
        uploadedFiles.bank_info_doc ? uploadedFiles.bank_info_doc : ""
      );
      formdata.append(
        "annual_revenue_doc",
        uploadedFiles.annual_revenue_doc ? uploadedFiles.annual_revenue_doc : ""
      );
      formdata.append(
        "financial_statement_doc",
        uploadedFiles.financial_statement_doc
          ? uploadedFiles.financial_statement_doc
          : ""
      );
      formdata.append(
        "memorandum_doc",
        uploadedFiles.memorandum_doc ? uploadedFiles.memorandum_doc : ""
      );
      formdata.append(
        "company_profile_doc",
        uploadedFiles.company_profile_doc
          ? uploadedFiles.company_profile_doc
          : ""
      );
      formdata.append(
        "autherize_dealer_doc",
        uploadedFiles.autherize_dealer_doc
          ? uploadedFiles.autherize_dealer_doc
          : ""
      );
      formdata.append(
        "certification_doc",
        uploadedFiles.certification_doc ? uploadedFiles.certification_doc : ""
      );
      if (isFile(uploadedFiles.business_license)) {
        formdata.append(
          "business_license",
          uploadedFiles.business_license ? uploadedFiles.business_license : ""
        );
      }
      if (isFile(uploadedFiles.tax_registration)) {
        formdata.append(
          "tax_registration",
          uploadedFiles.tax_registration ? uploadedFiles.tax_registration : ""
        );
      }
      if (isFile(uploadedFiles.certificate_of_incoporation)) {
        formdata.append(
          "certificate_of_incoporation",
          uploadedFiles.certificate_of_incoporation
            ? uploadedFiles.certificate_of_incoporation
            : ""
        );
      }
      if (isFile(uploadedFiles.copy_id_card)) {
        formdata.append(
          "copy_id_card",
          uploadedFiles.copy_id_card ? uploadedFiles.copy_id_card : ""
        );
      }

      formdata.append(
        "project_reference_doc",
        uploadedFiles.project_reference_doc
          ? uploadedFiles.project_reference_doc
          : ""
      );

      // const formDataObject = Object.fromEntries(formdata.entries());

      try {
        const isSuccess = await dispatch(updateVendorInfo(formdata));

        if (isSuccess?.data?.status === 200) {
          await dispatch(getVendorInfo());
          setEditMode(false);
          setPageLoading(false);
          toast.success(isSuccess?.data?.message);
        } else {
          setPageLoading(false);
          toast.error(isSuccess?.data?.message || "Something went wrong");
        }
      } catch (error) {
        console.log(error, "Error");
        toast.error(error.message || "Something went wrong");
        setPageLoading(false);
      }
    } else {
      setPageLoading(false);
      toast.error("Please Upload mandatory files and try again");
    }
  };

  const handleFileUpload = (fileType, fileData) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: fileData,
    }));
  };

  const getBusinessLicenseLabel = () => {
    if (["O", "U", "A"].includes(extractedValue)) {
      return "1. Business license or Excise Document";
    }
    return "1. Company Affidavit not over 6 months (หนังสือรับรอง ไม่เกิน6 เดือน)";
  };

  const getTaxRegistrationLabel = () => {
    if (["O", "U", "A"].includes(extractedValue)) {
      return "2. Tax Registration";
    }
    return t("2.VAT Registration Certificate(ภ.พ.20)");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <VpFiles
            icon={pngIcon}
            label={getBusinessLicenseLabel()}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.business_license_doc
                : userInfo.company_affidavit_doc
            }
            fileUrl={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.business_license_url
                : userInfo.company_affidavit_url
            }
            setPageLoading={setPageLoading}
            mandotary={["L", "T"].includes(extractedValue)}
            isDisable={extractedValue === "P"}
            onFileUpload={(fileData) =>
              handleFileUpload(
                ["O", "U", "A"].includes(extractedValue)
                  ? "business_license"
                  : "company_affidavit_doc",
                fileData
              )
            }
          />
          <VpFiles
            icon={pngIcon}
            label={getTaxRegistrationLabel()}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.tax_registration_doc
                : userInfo.vat_registration_doc
            }
            fileUrl={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.tax_registration_url
                : userInfo.vat_registration_url
            }
            mandotary={["L", "T"].includes(extractedValue)}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload(
                ["O", "U", "A"].includes(extractedValue)
                  ? "tax_registration"
                  : "vat_registration_doc",
                fileData
              )
            }
            isDisable={extractedValue === "P"}
          />
          <VpFiles
            icon={pngIcon}
            label={t("3.Book Bank or Bank information")}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.bank_info_doc}
            fileUrl={userInfo.bank_info_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("bank_info_doc", fileData)
            }
            mandotary={valdiateBookBank}
            isDisable={["AVT", "AYUDHAYA"].includes(getPaymentMethod)}
          />
          <VpFiles
            icon={pngIcon}
            label={t("4.Three Year Annual Revenue")}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.annual_revenue_doc}
            fileUrl={userInfo.annual_revenue_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("annual_revenue_doc", fileData)
            }
            isDisable={["P", "O", "U"].includes(extractedValue)}
          />
          <VpFiles
            icon={pngIcon}
            label={t("5.Three Year Financial Statements")}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.financial_statement_doc}
            fileUrl={userInfo.financial_statement_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("financial_statement_doc", fileData)
            }
            isDisable={["P", "O", "U"].includes(extractedValue)}
          />
          <VpFiles
            icon={pngIcon}
            label={
              ["O", "U", "A"].includes(extractedValue)
                ? "6. Certificate of incorporation"
                : "6. Memorandum of association (หนังสือบริคณห์สนธิ)"
            }
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.certificate_of_incoporation_doc
                : userInfo.memorandum_doc
            }
            fileUrl={
              ["O", "U", "A"].includes(extractedValue)
                ? userInfo.certificate_of_incoporation_url
                : userInfo.memorandum_url
            }
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload(
                ["O", "U", "A"].includes(extractedValue)
                  ? "certificate_of_incoporation"
                  : "memorandum_doc",
                fileData
              )
            }
            isDisable={extractedValue === "P"}
          />
          <VpFiles
            icon={pngIcon}
            label={
              ["P"].includes(extractedValue)
                ? "7. Copy of ID Card / Copy of house Registration"
                : t("7. Company profile / Product line")
            }
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={
              ["P"].includes(extractedValue)
                ? userInfo.copy_id_card_doc
                : userInfo.company_profile_doc
            }
            fileUrl={
              ["P"].includes(extractedValue)
                ? userInfo.copy_id_card_url
                : userInfo.company_profile_url
            }
            setPageLoading={setPageLoading}
            mandotary={["P"].includes(extractedValue)}
            onFileUpload={(fileData) =>
              handleFileUpload(
                ["P"].includes(extractedValue)
                  ? "copy_id_card"
                  : "company_profile_doc",
                fileData
              )
            }
          />
          <VpFiles
            icon={pngIcon}
            label="8. Authorize dealer if any"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.autherize_dealer_doc}
            fileUrl={userInfo.autherize_dealer_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("autherize_dealer_doc", fileData)
            }
            isDisable={extractedValue === "L"}
          />
          <VpFiles
            icon={pngIcon}
            label={t("9.Certificate document (for example ISO 9001 or any)")}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.certification_doc}
            fileUrl={userInfo.certification_url}
            setPageLoading={setPageLoading}
            isDisable={extractedValue === "L"}
            mandotary={["P", "T"].includes(extractedValue)}
            onFileUpload={(fileData) =>
              handleFileUpload("certification_doc", fileData)
            }
          />
          <VpFiles
            icon={pngIcon}
            label={t("10.Project reference")}
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={userInfo.project_reference_doc}
            fileUrl={userInfo.project_reference_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("project_reference_doc", fileData)
            }
          />
          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
            setDocEdit={setDocEdit}
            docEdit={docEdit}
          />
        </div>
      </form>
    </>
  );
};

export default VpDocuments;
