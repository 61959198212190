import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Nav from "react-bootstrap/Nav";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import JobExpandedTable from "./JobExpandedTable";
import CustomPagination from "../../CustomPagination/CustomPagination";
import TuneIcon from "@mui/icons-material/Tune";
import JobsFilter from "../JobsFilter";
import PrDetailsUnderJobs from "../../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";

const JobsTable = ({ tableData }) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("vms_vendor_token");

  // console.log("table data in jobs table", tableData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [data, setData] = useState(tableData);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [type, setType] = useState("all");
  const navigate = useNavigate();
  const [enableFilter, setEnableFilter] = useState(false);
  const [showfilteredData, setShowFilteredData] = useState(tableData);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // console.log(selectedCategories, "from parent component selected categories");
  // Callback function to receive selected categories from JobsFilter
  const handleCategoryChange = useCallback((selectedCategories) => {
    setSelectedCategories(selectedCategories);
  }, []);

  useEffect(() => {
    // Function to filter data based on selectedCategories
    const filterDataByCategories = (data, selectedCategories) => {
      if (selectedCategories?.length === 0) {
        return data; // If no categories are selected, return the original data
      }

      return data?.filter((item) => {
        // console.log(item?.PurchaseGroup?.trim()?.toLowerCase());
        if (
          selectedCategories.includes(
            item?.PurchaseGroup?.trim()?.toLowerCase()
          )
        ) {
          return true;
        }

        // Check child objects if they exist
        if (item?.child && item?.child.length > 0) {
          const childMatches = item?.child?.some((childItem) =>
            selectedCategories?.includes(
              childItem?.PurchaseGroup?.trim()?.toLowerCase()
            )
          );
          return childMatches;
        }

        return false;
      });
    };

    const filteredData = filterDataByCategories(tableData, selectedCategories);
    // console.log(filteredData, "filtered data");
    setShowFilteredData(filteredData);
  }, [tableData, selectedCategories]);

  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const toggleFilter = () => {
    setEnableFilter(!enableFilter);
  };

  const ExpandedComponent = ({ data }) => (
    <pre>
      <JobExpandedTable data={data.child} />
    </pre>
  );

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#003595",
        color: "white",
        fontSize: "15px",
      },
    },
  };

  const columns = [
    {
      name: t("Location"),
      selector: "Location",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("PR No."),
      selector: "PRNo",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("PR Title"),
      selector: "PRTitle",
      sortable: true,
      // width: "15%",
    },
    {
      name: t("Purchase Group"),
      selector: "PurchaseGroup",
      sortable: true,
      // width: "15%",
    },

    {
      name: t("Date Start"),
      selector: "Deliverydate",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("Date End"),
      selector: "Deliverydate",
      sortable: true,
      // width: "10%",
    },
    {
      name: t("Delivery Date"),
      selector: "Deliverydate",
      sortable: true,
      // width: "10%",
    },
  ];

  if (token) {
    // Add the "Action" column when the token exists
    columns.push({
      name: t("Action"),
      selector: "Action",
      cell: (result) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ gap: "5px" }}
        >
          <button
            className="jobs-view-btn"
            onClick={() => {
              handleViewDetail(result);
            }}
          >
            {t("View Detail")}
          </button>
        </div>
      ),
    });
  }

  const handleViewDetail = (result) => {
    console.log("View Detail", result);
    setIsModalOpen(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  console.log(showfilteredData, "showFilteredData");

  const filteredDataByType =
    type === "all"
      ? showfilteredData
      : showfilteredData.filter((item) => item.status === type);

  // console.log(filteredDataByType, "filtered data by type");

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filteredDataByType.length),
    total: filteredDataByType.length,
  };

  const paginatedData = filteredDataByType.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getCountByStatus = (status) => {
    return tableData.filter((item) => item.status === status).length;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // useEffect(() => {
  //   setShowFilteredData(tableData);
  // }, []);

  return (
    <>
      {" "}
      {!showfilteredData.length > 0 ? (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="jobs-table-header-div">
                    <div className="jobs-table-head">
                      <Nav activeKey="/home" onSelect={handleTypeSelect}>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === "all"
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="all"
                          >
                            All
                            <span
                              className={
                                type === "all"
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="all"
                            >
                              {tableData?.length}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === "Goods"
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="Goods"
                          >
                            {t("Goods")}
                            <span
                              className={
                                type === "Goods"
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="Goods"
                            >
                              {getCountByStatus("Goods")}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === "Service"
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="Service"
                          >
                            {t("Service")}
                            <span
                              className={
                                type === "Service"
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="Service"
                            >
                              {getCountByStatus("Service")}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            className={
                              type === "Project"
                                ? "announcement-table-nav-active"
                                : "announcement-table-nav"
                            }
                            eventKey="Project"
                          >
                            {t("Project")}
                            <span
                              className={
                                type === "Project"
                                  ? "announcement-top-badge-active"
                                  : "announcement-top-badge"
                              }
                              eventKey="Project"
                            >
                              {getCountByStatus("Project")}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      <div className="d-flex justify-content-between align-items-center my-3">
                        <div>
                          <button
                            className="filter-button"
                            onClick={toggleFilter}
                          >
                            <TuneIcon /> {t("Filter")}
                          </button>

                          {enableFilter ? (
                            <JobsFilter
                              tableData={tableData}
                              onCategoryChange={handleCategoryChange}
                            />
                          ) : null}
                        </div>

                        <div className="px-3" style={{ whiteSpace: "nowrap" }}>
                          <label for="" style={{ color: "#003595" }}>
                            {t("Show")}
                          </label>
                          <select
                            className="show-select-options px-2 mx-2"
                            value={itemsPerPage}
                            onChange={handleDropdownChange}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                          </select>
                          <label>{t("entries")}</label>
                        </div>
                      </div>
                    </div>

                    <DataTable
                      className="jobs-table"
                      columns={columns}
                      data={paginatedData}
                      customStyles={customStyles}
                      pagination={false}
                      noDataComponent="No records to display"
                      expandableRows={true}
                      expandableRowDisabled={(row) => !row.child}
                      expandableRowExpanded={(row) => row.child}
                      expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="job-show-entries">
              {" "}
              Showing {displayedRange.start} to {displayedRange.end} of{" "}
              {displayedRange.total} entries
            </p>
            <CustomPagination
              totalItems={filteredDataByType.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <h1 className="d-flex justify-content-center align-items-center">
          {" "}
          No data available{" "}
        </h1>
      )}
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
        />
      )}
    </>
  );
};

export default JobsTable;
