export const COUNTRY_LIST = "statecity-common/Get-Country";
export const STATE_LIST = "statecity-common/Get-State-By-Country?country_id=";
export const CITY_LIST = "statecity-common/Get-City-By-Country-State?state_id=";
export const VENDOR_REGISTER = "vendor/Vendor-Registration";
export const CHECK_TAX_NO = "vendor/check-tax-Registration?registration_no=";

// Vendor side login API endpoint

export const VENDOR_LOGIN = "vendor/Vendor-login";
export const VENDOR_RESET_PASSWORD = "vendor/Vendor-Forgot-P-I";
export const VENDOR_RESET_PASSWORD_UPDATE = "vendor/Vendor-Password-Update";
export const VENDOR_RESET_PASSWORD_VERIFY = "vendor/Vendor-Password-Otp-Verify";
export const VENDOR_RESEND_OTP = "vendor/Vendor-Resend-Otp";
export const VENDOR_UPDATE_DETAILS = "vendor/Vendor-Update";
export const VENDOR_DELETE_DOC = "vendor/Vendor-Delete-Document";
export const VENDOR_DETAILS = "vendor/Vendor-Details";

// press release api
export const GET_ALL_PRESS = "press/Get-All-Press-public";
