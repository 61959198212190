import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { MultiSelect } from "react-multi-select-component";
import { useTranslation } from "react-i18next";
import pngIcon from "../../Assets/Images/png-icon.png";
import VpFiles from "./VpFiles/VpFiles";
import { useDispatch, useSelector } from "react-redux";
import { handleKeyDown, ppeOptions } from "../../Utils/Utils";
import {
  getVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { toast } from "react-toastify";
import useIsEditFieldMandatory from "../../CustomHooks/useIsEditFieldMandatory";

const Hse = ({ editMode, setEditMode, setPageLoading, data }) => {
  const {
    register,
    handleSubmit,
    // getValues,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const isFieldMandatory = useIsEditFieldMandatory();
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.UserInfoReducer);

  const recongizedAsArray = userInfo?.ppe?.includes(",")
    ? userInfo.ppe.split(",")
    : [userInfo.ppe];

  const initialSelected =
    userInfo?.ppe?.length > 0
      ? recongizedAsArray.map((value) => ({
          label: value,
          value,
        }))
      : [];
  const [selectedPpe, setSelectedPpe] = useState(initialSelected);

  function convertStringToArray(str) {
    // console.log("convertString", str.length);
    if (str && str.length > 0) {
      if (str.includes(",")) {
        return str.split(",");
      } else {
        return [str];
      }
    } else {
      return [str];
    }
  }

  const disableFirstField =
    userInfo?.type_of_business !== "Transportation" &&
    userInfo?.type_of_business !== "Service";
  const onSubmit = async (data) => {
    data.ppe = Array.isArray(data?.ppe)
      ? data?.ppe?.map((option) => option?.value)
      : data?.ppe;

    setPageLoading(true);

    const formdata = new FormData();
    formdata.append("health_safty_management", data.health_safty_management);
    formdata.append("qhse_management", data.qhse_management);
    formdata.append("injury_deaths", data.injury_deaths);
    formdata.append("injury_death_specify", data.injury_death_specify);
    formdata.append(
      "registered_foreigner_labor",
      data.registered_foreigner_labor
    );
    formdata.append("lower18_labor", data.lower18_labor);
    formdata.append("repair_equipment_pm", data.repair_equipment_pm);
    formdata.append(
      "equipment_tools_doc",
      uploadedFiles.equipment_tools_doc
        ? uploadedFiles.equipment_tools_doc
        : null
    );
    formdata.append(
      "ndt_personal_doc",
      uploadedFiles.ndt_personal_doc ? uploadedFiles.ndt_personal_doc : null
    );
    formdata.append(
      "organization_chart_doc",
      uploadedFiles.organization_chart_doc
        ? uploadedFiles.organization_chart_doc
        : null
    );
    formdata.append(
      "qa_qc_policy_doc",
      uploadedFiles.qa_qc_policy_doc ? uploadedFiles.qa_qc_policy_doc : null
    );
    formdata.append(
      "technical_doc",
      uploadedFiles.technical_doc ? uploadedFiles.technical_doc : null
    );
    formdata.append("ppe_specify", data.ppe_specify);
    formdata.append("ppe", data.ppe);

    try {
      const isSuccess = await dispatch(updateVendorInfo(formdata));

      if (isSuccess?.data?.status === 200) {
        await dispatch(getVendorInfo());
        setEditMode(false);
        setPageLoading(false);
        toast.success(isSuccess?.data?.message);
      } else {
        setPageLoading(false);
        toast.error(isSuccess?.data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error, "Error");
      toast.error(error.message || "Something went wrong");
      setPageLoading(false);
    }
  };

  const onChangeDropDownPpe = (value) => {
    setValue("ppe", value);
    setSelectedPpe(value);
  };

  const handleFileUpload = (fileType, fileData) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fileType]: fileData,
    }));
  };

  useEffect(() => {
    Object.keys(userInfo)?.map((key) => {
      setValue(key, userInfo[key]);
    });
  }, [userInfo]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Does your company implement, maintain and improve anmoccupational health and safety management system?"
              )}
              {!disableFirstField && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode || disableFirstField}
                className="vp-input"
                {...register("health_safty_management", {
                  required: !disableFirstField,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.health_safty_management && (
                <span className="error-msg">
                  Please select whether your company operates, maintains, and
                  improves occupational health and safety management systems.
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Does your company provide PPE for your staff? (Multiple options can be selected.)"
              )}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>

            <div className="vp-input-div vp-supplier-multi">
              <div
                className={
                  editMode
                    ? "vp-input categories-multi-drop-down"
                    : "vp-input categories-multi-drop-down vp-multi-input-div disabled-dropdown-multiselect"
                }
                style={{
                  padding: "0px",
                  width: "61%",
                  minWidth: "100%",
                  height: "38px",
                }}
                id={!editMode && "disabled-div"}
              >
                <Controller
                  name="ppe"
                  control={control}
                  rules={{
                    required: isFieldMandatory
                      ? t("Please select at least one ppe.")
                      : false,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <MultiSelect
                        className={editMode ? "" : "vp-supplier-multi-select"}
                        options={ppeOptions}
                        {...field}
                        value={selectedPpe}
                        onChange={(value) => {
                          field.onChange(value);
                          onChangeDropDownPpe(value);
                        }}
                        labelledBy={t("select")}
                        hasSelectAll={false}
                      />
                      {fieldState.error && (
                        <span className="error-msg mt-3">
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />

                {/* <DropdownMultiselect
                  selected={convertStringToArray(userInfo?.ppe).filter(
                    (option) =>
                      [
                        "Safety shoes",
                        "Safety helmet",
                        "Safety Glassed/Goggle",
                        "Chemical Gloves",
                        "Long sleeves shirt",
                        "Half Mask & Respirator",
                        "Other. please specify",
                      ].includes(option)
                  )}
                  handleOnChange={(value) => {
                    onChangeDropDownPpe(value);
                  }}
                  {...register("ppe", {
                    required: isFieldMandatory,
                  })}
                  options={[
                    "Safety shoes",
                    "Safety helmet",
                    "Safety Glassed/Goggle",
                    "Chemical Gloves",
                    "Long sleeves shirt",
                    "Half Mask & Respirator",
                    "Other. please specify",
                  ]}
                  placeholder="select"
                  name="ppe"
                  selectDeselectLabel=""
                /> */}
              </div>
              {errors.ppe && (
                <span className="error-msg">
                  Please select whether your company provides PPE to your
                  employees.
                </span>
              )}

              <div className="vp-input-div" style={{ display: "contents" }}>
                {Array.isArray(selectedPpe) &&
                  selectedPpe.some(
                    (option) => option?.value === "Other. please specify"
                  ) && (
                    <>
                      <input
                        type="text"
                        style={{ width: "100%", minWidth: "100% !important" }}
                        disabled={!editMode}
                        className="vp-input mt-3"
                        {...register("ppe_specify", {
                          required: true,
                          maxLength: 80,
                        })}
                      />

                      {errors.ppe_specify && (
                        <span className="error-msg">
                          {t("Please enter other specific data")}
                        </span>
                      )}
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Have your company ever gotten any prize concerning SAFETY management?"
              )}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("qhse_management", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.qhse_management && (
                <span className="error-msg">
                  Please select whether your company has received any awards
                  related to SAFETY management.{" "}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Does your company occur any accident during the year, if yes please give detail of injury / death?"
              )}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("injury_deaths", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>

              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("injury_deaths") &&
                  watch("injury_deaths").includes("Yes") && (
                    <>
                      <input
                        type="text"
                        disabled={!editMode}
                        className="vp-input mt-3"
                        {...register("injury_death_specify", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                      {errors.injury_death_specify && (
                        <span className="error-msg">
                          {t("Please enter other specific data")}
                        </span>
                      )}
                    </>
                  )}
              </div>
              {errors.injury_deaths && (
                <span className="error-msg">
                  Please select whether your company has experienced any
                  incidents during the year.{" "}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Does your company have registered foreigner labor ?")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("registered_foreigner_labor", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.registered_foreigner_labor && (
                <span className="error-msg">
                  {t("Please select the correct value")}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input">
            <label className="vp-label">
              {t("Does your company have registered age lower than 18 years?")}
              {isFieldMandatory && editMode && (
                <span className="staricon">*</span>
              )}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("lower18_labor", {
                  required: isFieldMandatory,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.lower18_labor && (
                <span className="error-msg">
                  Please select whether your company have registered age lower
                  than 18 years{" "}
                </span>
              )}
            </div>
          </div>
          <div className="vp-label-input py-4">
            <label
              className="vp-label"
              style={{ whiteSpace: "nowrap", fontSize: "14px" }}
            >
              {t(
                "If vendor click type of business is service / project must fill the Contractor/Service/Project Data as below / not mandatory for attached file."
              )}
              "
            </label>
          </div>
          <div className="vp-label-input mb-5">
            <label className="vp-label">
              {t("Does your company inspect service & repair equipment as PM?")}{" "}
              :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("repair_equipment_pm", {
                  required: false,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.repair_equipment_pm && (
                <span className="error-msg">
                  {t("Please select the correct value")}
                </span>
              )}
            </div>
          </div>

          <VpFiles
            icon={pngIcon}
            label="Organization Chart"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={data?.organization_chart_doc}
            fileSize={"10kb"}
            fileUrl={data?.organization_chart_url}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("organization_chart_doc", fileData)
            }
          />

          <VpFiles
            icon={pngIcon}
            label="QA/QC Policy/Manual/Work procedure"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={data?.qa_qc_policy_doc}
            fileUrl={data?.qa_qc_policy_url}
            fileSize={"10kb"}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("qa_qc_policy_doc", fileData)
            }
          />

          <VpFiles
            icon={pngIcon}
            label="QA/QC, Welder, NDT personal certificate/CV"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={data?.ndt_personal_doc}
            fileUrl={data?.ndt_personal_url}
            fileSize={"10kb"}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("ndt_personal_doc", fileData)
            }
          />

          <VpFiles
            icon={pngIcon}
            label="Technical Manual/ Document"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={data?.technical_doc}
            fileUrl={data?.technical_url}
            fileSize={"10kb"}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("technical_doc", fileData)
            }
          />

          <VpFiles
            icon={pngIcon}
            label="List of equipment and tools"
            editMode={editMode}
            setEditMode={setEditMode}
            fileCount={1}
            fileName={data?.equipment_tools_doc}
            fileUrl={data?.equipment_tools_url}
            fileSize={"10kb"}
            setPageLoading={setPageLoading}
            onFileUpload={(fileData) =>
              handleFileUpload("equipment_tools_doc", fileData)
            }
          />

          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
          />
        </div>
      </form>
    </>
  );
};

export default Hse;
