import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomPagination from "../CustomPagination/CustomPagination";
import csvLogo from "../../Assets/Images/csvLogo.png";
import CsvDownloadButton from "react-json-to-csv";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import "./VendorFollowTable.css";

const VendorFollowTable = (props) => {
  const mockData = [
    {
      Topic: "PC6A Extenstion",
      PONo: "4501426994",
      Currency: "VP7767",
      DeliveryDate: "2023-08-11",
      Qty: "2.000",
      Unit: "JOB",
      Amount: "842,000.00",
      status: "delivered",
    },
    {
      DeliveryDate: "2023-09-10",
      PONo: "4501426994",
      Topic: "PC6A Extenstion",
      Amount: "842,000.00",
      Currency: "PP123",
      Qty: "3.000",
      Unit: "JOB",
      status: "delivering",
    },

    {
      DeliveryDate: "2022-02-02",
      PONo: "4501426994",
      Topic: "PC6A Extenstion",
      Amount: "842,000.00",
      Currency: "PP123",
      Qty: "2.000",
      Unit: "JOB",
      status: "delayed",
    },
    {
      DeliveryDate: "2023-06-05",
      PONo: "4501426994 ",
      Topic: "PC6A Extenstion",
      Amount: "842,000.00",
      Currency: "PP123",
      Qty: "3.000",
      Unit: "JOB",
      status: "delivering",
    },
    {
      DeliveryDate: "2023-06-05",
      PONo: "4501426994 ",
      Topic: "PC6A Extenstion",
      Amount: "842,000.00",
      Currency: "PP123",
      Qty: "1.000",
      Unit: "lt",
      status: "delayed",
    },
    {
      DeliveryDate: "2023-06-05",
      PONo: "4501426994 ",
      Topic: "PC6A Extenstion",
      Amount: "axasskk",
      Currency: "PP123",
      Qty: "5.000",
      Unit: "ct",
      status: "delivered",
    },
  ];

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValues, setSearchValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(mockData);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [type, setType] = useState("all");
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#103A5D",
        color: "white",
        fontSize: "12px",
        fontFamily: "Sarabun",
        fontWeight: "400",
        width: "100%",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "12px",
        color: "#103a5d",
        height: "auto",
        fontWeight: "400",
        letterSpacing: "0.5px",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
        fontWeight: "500",
      },
    },
  };

  const columns = [
    {
      id: "PONo",
      name: t("PO No."),
      selector: "PONo",
      sortable: true,
      width: "11%",
    },
    {
      id: "Topic",
      name: t("Topic"),
      selector: "Topic",
      width: "10%",
    },
    {
      id: "DeliveryDate",
      name: t("Delivery Date(PO)"),
      selector: "DeliveryDate",
      sortable: true,
      width: "10%",
    },
    {
      id: "Qty",
      name: t("Qty"),
      selector: "Qty",
      width: "7%",
    },
    {
      id: "Unit",
      name: t("Unit"),
      selector: "Unit",
      width: "7%",
    },
    {
      id: "Amount",
      name: t("Amount"),
      selector: "Amount",
      sortable: true,
      width: "9%",
    },
    {
      id: "Currency",
      name: t("Currency"),
      selector: "Currency",
      sortable: true,
      width: "10%",
    },
    {
      id: "Status",
      name: t("Status"),
      width: "12%",
      selector: "status",
      searchable: false,
      cell: (result) => (
        <div
          className={
            result.status === "delivered"
              ? "table-status delivered"
              : result.status === "delayed"
              ? "table-status delayed"
              : result.status === "delivering"
              ? "table-status delivering"
              : ""
          }
        >
          {result.status}
        </div>
      ),
    },
    {
      id: "Action",
      width: "24%",
      name: t("Action"),
      cell: (result) => (
        <div className="d-flex">
          {result.status === "delivered" && (
            <>
              <button
                className="table-btn"
                onClick={() => {
                  handleViewPr();
                }}
              >
                {t("View")}
              </button>
            </>
          )}
          {result.status === "delivering" && (
            <button
              className="table-btn"
              onClick={() => {
                handleViewPr();
              }}
            >
              {t("View")}
            </button>
          )}
          {result.status === "delayed" && (
            <>
              <button
                className="table-btn"
                onClick={() => {
                  handleViewPr();
                }}
              >
                {t("View")}
              </button>
              <button className="ven-foll-sp-btn" onClick={props.DelayClicked}>
                {t("Show Penalty")}
              </button>
            </>
          )}
        </div>
      ),
      searchable: false,
    },
  ];

  const handleViewPr = (result) => {
    // setIsModalOpen(true);
    navigate("/po-details");
  };
  //Penalty reject  popup
  // const [PenaltyRejectModalOpen, setPenaltyRejectIsModalOpen] = useState(false);
  // const PenaltyRejectOpenModal = () => {
  //   setPenaltyRejectIsModalOpen(true);
  // };
  // const PenaltyRejectCloseModal = () => {
  //   setPenaltyRejectIsModalOpen(false);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  const handleTypeSelect = (selectedKey) => setType(selectedKey);

  const filterData = () => {
    let filteredData = [...data];

    for (const columnName in searchValues) {
      const searchValue = searchValues[columnName];
      if (searchValue) {
        filteredData = filteredData.filter((item) =>
          item[columnName].toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    }

    if (selectedDateRange[0] && selectedDateRange[1]) {
      filteredData = filteredData.filter((item) => {
        const registerDate = new Date(item["DeliveryDate"]);
        return (
          registerDate >= selectedDateRange[0] &&
          registerDate <= selectedDateRange[1]
        );
      });
    }
    return filteredData;
  };

  const filteredData = filterData();

  const filteredDataByType =
    type === "all"
      ? filteredData
      : filteredData.filter((item) => item.status === type);

  // console.log(filteredDataByType);

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, filteredDataByType.length),
    total: filteredDataByType.length,
  };

  const paginatedData = filteredDataByType.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const headerNames = columns.map((column) => column.name);

  const csvData = data.map((item) =>
    columns.map((column) => item[column.selector])
  );

  const getCountByStatus = (status) => {
    return data.filter((item) => item.status === status).length;
  };

  return (
    <>
      <div className="um-table-header-div">
        <div className="um-table-head">
          <div className="vendor-foll-table-navbar">
            <Nav activeKey="" onSelect={handleTypeSelect} className="">
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "all"
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="all"
                >
                  {t("All")}
                  <span
                    className={
                      type === "all"
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="all"
                  >
                    {data.length}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "delivering"
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="delivering"
                >
                  {t("Delivering")}
                  <span
                    className={
                      type === "delivering"
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="delivering"
                  >
                    {getCountByStatus("delivering")}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "delivered"
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="delivered"
                >
                  {t("Delivered")}
                  <span
                    className={
                      type === "delivered"
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="delivered"
                  >
                    {getCountByStatus("delivered")}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={
                    type === "delayed"
                      ? "vendor-follow-visit-table-active"
                      : "vendor-follow-visit-table"
                  }
                  eventKey="delayed"
                >
                  {t("Delayed")}
                  <span
                    className={
                      type === "delayed"
                        ? "vendor-follow-visit-table-badge-active"
                        : "vendor-follow-visit-table-badge"
                    }
                    eventKey="delayed"
                  >
                    {getCountByStatus("delayed")}
                  </span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center um-entries">
              {" "}
              <p>{t("Show")} </p>{" "}
              <select value={itemsPerPage} onChange={handleDropdownChange}>
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>25</option>
                <option>30</option>
              </select>{" "}
              <span>{t("entries")}</span>
            </div>
            <CsvDownloadButton
              data={csvData}
              filename="vendor_follow_data.csv"
              headers={headerNames}
              delimiter={","}
              style={{
                backgroundColor: "#fafbff",
                border: "0px solid #a511c0",
                display: "inline-block",
                cursor: "pointer",
                fontSize: "15px",
                fontWeight: "bold",
                padding: "6px 24px",
                textDecoration: "none",
                textShadow: "0px 1px 0px #9b14b3",
              }}
            >
              <img src={csvLogo} alt="" />
            </CsvDownloadButton>
          </div>
        </div>
        <div
          style={{ position: "relative", margin: "0px" }}
          className={
            paginatedData.length ? "um-data-table" : "um-empty-data-table"
          }
        >
          <DataTable
            columns={columns}
            data={paginatedData}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
          />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <p className="um-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {displayedRange.total} entries
        </p>
        <CustomPagination
          totalItems={filteredDataByType.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
      {/* PenaltyRejectPopup */}
      {/* <PenaltyRejectPopup
        PenaltyRejectIsOpen={PenaltyRejectModalOpen}
        PenaltyRejectFormTitle={"Penalty reject"}
        PenaltyRejectCloseModal={PenaltyRejectCloseModal}
        
      />  */}
    </>
  );
};

export default VendorFollowTable;
