import React, { useState, useRef, useEffect } from "react";
import Header from "../../Components/header";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./otp.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  VENDOR_RESEND_OTP,
  VENDOR_RESET_PASSWORD_VERIFY,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const OtpVerification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useForm();
  const [showResend, setShowResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [delay, setDelay] = useState(600);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  const user_reset_token = localStorage.getItem("user_reset_token");
  // const user_latest_token = localStorage.getItem("user_latest_token")
  const user_name = localStorage.getItem("user_name");

  // const [token,  setToken] = useState(user_reset_token);
  // console.log("token in state", token);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  const onClickJobs = () => {
    navigate("/jobs");
  };

  const onClickVendorGuide = () => {
    navigate("/vendor-guide");
  };

  const tokenToSend = user_reset_token;

  const onSubmit = () => {
    const data = {
      otp: otp,
    };
    console.log("otpdata", data);
    axios
      .post(`${BaseUrl}${VENDOR_RESET_PASSWORD_VERIFY}`, data, {
        headers: { Authorization: `Bearer ${tokenToSend}` },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          navigate("/reset-password-page");
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
          setShowResend(true);
        }
      })
      .catch((err) => {
        console.log("otp errors", err);
      });
  };

  const handleResend = () => {
    // setToken(true);
    // console.log("token", token)
    setShowResend(false);
    const data = {
      user_name: user_name,
    };

    axios
      .post(`${BaseUrl}${VENDOR_RESEND_OTP}`, data)
      .then((res) => {
        localStorage.removeItem("user_reset_token");
        localStorage.setItem("user_reset_token", res.data.data.token);
        // setToken(res.data.data.token)
        if (res.data.status === 200) {
          toast.success("OTP Resended  Successfully");
          setOtp("");
          setDelay(600);
        } else if (res.data.status === 401) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error("resend error", err);
      });
  };
  return (
    <div className="mainpage">
      <div className="pt-3">
        <Header />
      </div>
      <div className="loginpage">
        <div className="login" style={{ marginTop: "0px" }}>
          <div className="col-12 col-md-6 col-lg-6 d-none d-sm-block">
            <div className="login-page-left-card">
              {/* <img src="/images/VendorPortal.png" alt=""  className='blur-image'/> */}
              <p className="small">AGC VINYTHAI</p>
              <h1 className="main">
                {t("VENDOR")} <br /> {t("MANAGEMENT SYSTEM")}
              </h1>
              <div className="buttons pt-4">
                <button onClick={onClickVendorGuide} className="vendor-btn ">
                  {t("Vendor Guide")}
                </button>
                <button onClick={onClickJobs} className="job-btn mx-2">
                  {t("Jobs")}
                </button>
              </div>
              <div className="left-card-bottom mb-1">
                <div>
                  <CopyrightIcon style={{ width: "12px" }} />
                </div>{" "}
                &nbsp;
                <div>2022 AGC Vinythai. All Right Reserved</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6  col-sm-12">
            <div className="reset-page-right-card">
              <h2 className="mt-4 pt-4 second-heading">
                {t("Otp verification")}
              </h2>
              <p className="reset-sub-content">
                {t("We have sent a 6 - digit verification code")} <br />{" "}
                {t("to the Email Address")}
              </p>
              <div className="">
                <span className="otp-time">
                  {minutes}:{seconds}
                </span>
              </div>
              <div className="otp-inputs">
                <OtpInput
                  inputType="number"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input className="otp-inputs" {...props} />
                  )}
                />
              </div>

              <div className="mt-4">
                <button
                  className="loginbutton"
                  onClick={onSubmit}
                  disabled={otp.length < 6 || delay === 0}
                >
                  {t("Continue")}
                </button>
              </div>

              <div className="mt-2">
                <p className="fifth-heading">
                  {t("Don’t Received Code ?")}{" "}
                  <span
                    style={
                      showResend || delay === 0
                        ? { color: "red", cursor: "pointer" }
                        : { cursor: "not-allowed", color: "#ebebeb" }
                    }
                    onClick={showResend || delay === 0 ? handleResend : null}
                  >
                    {t("Resend")}
                  </span>
                </p>
              </div>

              <div
                className="right-card-bottom mt-6"
                style={{ marginTop: "auto", marginBottom: "18px" }}
              >
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span> {t("Terms of Service")} | </span>
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1"> {t("Privacy Policy")} | </span>{" "}
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1">AVT Privacy Policy | </span>{" "}
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  <span className="px-1"> {t("Accessibility")} </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
