import React, { useState } from "react";
import JobsTable from "../../Components/Jobs/JobsTable";
import Login from "../../Components/Login/Login";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import Announce from "../registrationTopNav";
import "./jobs.css";
import Header from "../../Components/header";
import { useTranslation } from "react-i18next";

const AnnouncementsJobs = () => {
  const token = localStorage.getItem("vms_vendor_token");
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const mockData = [
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "ELECTRICAL GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Goods",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Safety & Protection Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Packaging",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Health-envi services",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-04",
      Location: "def",
      PRNo: "mtp2",
      PRTitle: "axasscc",
      PurchaseGroup: "PIPING & FITTINGS GOODS",
      Qty: "3",
      Buyer: "rt",
      status: "Goods",
    },

    {
      Deliverydate: "2023-06-05",
      Location: "pqr",
      PRNo: "mtp15",
      PRTitle: "axasspp",
      PurchaseGroup: "Health-envi services",
      Qty: "4",
      Buyer: "rt",
      status: "Service",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "stu",
      PRNo: "mtp16",
      PRTitle: "axassqq",
      PurchaseGroup: "It Services",
      Qty: "2",
      Buyer: "ot",
      status: "Service",
      child: [
        {
          PurchaseRequistion: "ssss",
          Description: "zxx hose 3/4”, SUS315",
          PurchaseGroup: "It Services",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: null,
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "It Services",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
      ],
    },

    {
      Deliverydate: "2023-06-05",
      Location: "ghi",
      PRNo: "mtp21",
      PRTitle: "axassvv",
      PurchaseGroup: "Piping & Fittings Goods",
      Qty: "3",
      Buyer: "wt",
      status: "Project",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Safety & Protection Goods",
      Qty: "1",
      Buyer: "ot",
      status: "Goods",
    },
    {
      Deliverydate: "2023-06-01",
      Location: "abc",
      PRNo: "mtp1",
      PRTitle: "axassbb",
      PurchaseGroup: "OFFICE GOODS",
      Qty: "2",
      Buyer: "qe",
      status: "Goods",
      child: [
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "Lab & Chemical Goods",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Axxxxxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "aaap",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "INSPECTION SERVICE",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },
    {
      Deliverydate: "2023-06-04",
      Location: "def",
      PRNo: "mtp2",
      PRTitle: "axasscc",
      PurchaseGroup: "INSPECTION SERVICE",
      Qty: "3",
      Buyer: "rt",
      status: "Goods",
    },

    {
      Deliverydate: "2023-06-05",
      Location: "pqr",
      PRNo: "mtp15",
      PRTitle: "axasspp",
      PurchaseGroup: "CAPEX",
      Qty: "4",
      Buyer: "rt",
      status: "Service",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "stu",
      PRNo: "mtp16",
      PRTitle: "axassqq",
      PurchaseGroup: "CHEMICALS",
      Qty: "2",
      Buyer: "ot",
      status: "Service",
      child: [
        {
          PurchaseRequistion: "ssss",
          Description: "zxx hose 3/4”, SUS315",
          PurchaseGroup: "fdp",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: null,
          Description: "Flexible hose 3/4”, SUS315",
          PurchaseGroup: "aaap",
          item: "1",
          Quantity: "3",
          Unit: "EA",
        },
        {
          PurchaseRequistion: "Aaaaaxxxx",
          Description: "abcd hose 3/4”, SUS315",
          PurchaseGroup: "fdaap",
          item: "1",
          Quantity: "30",
          Unit: "EA",
        },
      ],
    },

    {
      Deliverydate: "2023-06-05",
      Location: "ghi",
      PRNo: "mtp21",
      PRTitle: "axassvv",
      PurchaseGroup: "Lab & Chemical Goods",
      Qty: "3",
      Buyer: "wt",
      status: "Project",
    },
    {
      Deliverydate: "2023-06-05",
      Location: "jkl",
      PRNo: "mtp22",
      PRTitle: "axassww",
      PurchaseGroup: "Instrument service",
      Qty: "1",
      Buyer: "ot",
      status: "Goods",
    },
  ];

  const filteredData = mockData?.filter((item) => {
    // Convert all values to lowercase for a case-insensitive search
    const lowerCaseSearchQuery = searchQuery?.toLowerCase();

    // console.log(lowerCaseSearchQuery, "lowerCaseSearchQuery");

    // Check if any column in the item contains the search query
    return Object.values(item)?.some((value) =>
      String(value)?.toLowerCase()?.includes(lowerCaseSearchQuery)
    );
  });

  // console.log("filterdata", filteredData);

  // onChange event handler to the search input
  const handleSearchInputChange = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {!token ? (
        <div>
          <DefaultLayout>
            <div className="loginpage">
              <Login />
            </div>
          </DefaultLayout>
        </div>
      ) : (
        <div className="resetcontentpage">
          <Header />
        </div>
      )}
      <div className="">
        <div className="announce-navbar">
          <Announce />
        </div>
        <div className="announcement-page-background ">
          <div className="announcement-jobs-top">
            <h1 className="announcement-jobs-top-header py-2">
              {t("Announcements")}
            </h1>
            <input
              type="search"
              placeholder={t("Search Announcements")}
              className="announcement-jobs-top-search py-2 px-3"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      <div className="jobs-body mb-5">
        <JobsTable tableData={filteredData} />
      </div>
    </>
  );
};

export default AnnouncementsJobs;
