import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import UploadImage from "../../Assets/Svg/drop-zone.svg";
import CancelIcon from "../../Assets/Images/closeIcon.svg";
import PDFImage from "../../Assets/Svg/pdf-icon.svg";
import questionIcon from "../../Assets/Images/questionmarkIcon.svg";
import "./DropZone.css";

const DropZone = () => {
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    onDrop,
  });

  const files = myFiles?.map((file) => (
    <div className="uploaded-document-show my-2">
      <div className="d-flex align-items-center justify-content-center ">
        <div>
          <img
            src={PDFImage}
            alt=""
            style={{ width: "24px", height: "30px" }}
          />
        </div>
        <div className="text-center px-3">
          <p className="dropzone-file-name" key={file.path}>
            {file.path}
          </p>
        </div>
      </div>
      <div onClick={removeFile(file)} style={{ cursor: "pointer" }}>
        <img
          src={CancelIcon}
          alt=""
          style={{ width: "30px", height: "30px" }}
        />
      </div>
    </div>
  ));
  return (
    <div className="py-3">
      <div className="d-flex justify-content-between">
        <h4 className="dropzone-heading">Quotation</h4>
        <img src={questionIcon} alt="" />
      </div>
      <section className="upload-file-dropzone p-3">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <img src={UploadImage} alt="" />
          </div>
        </div>
      </section>
      <div>
        <div className="drop-zone-document-title-div pt-4">
          <label className="drop-zone-document-title">Document Files</label>
        </div>
        <div className="">{files}</div>
      </div>
    </div>
  );
};

export default DropZone;
