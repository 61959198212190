import React from "react";
// import SamplePdf from "./test.pdf";

const VendorGuide = (props) => {
  const { pdf } = props;
  // console.log(SamplePdf, "pdf");
  return (
    <>
      <h1 className="d-flex align-items-center justify-content-center">
        PDF Not Available
      </h1>
      {/* 
      <iframe
        src={pdf}
        // frameBorder="0"
        // scrolling="auto"
        title="PDF Viewer"
        height="1000px"
        width="100%"
      ></iframe> */}
    </>
  );
};

export default VendorGuide;
