import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import closeIcon from "../../Assets/Svg/close-icon.svg";
import "./CustomPopup.css";
// import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const CustomPopup = ({
  isOpenPopup,
  closePopup,
  title,
  text,
  image,
  btn1,
  btn2,
  successTitle,
  successText,
  successIcon,
  btn3,
  closePrDetails,
}) => {
  const [successPopup, setShowSuccessPopup] = useState(false);

  const onClickConfirm = async () => {
    console.log("onClickConfirm");
    toast.success("successfully");
    setShowSuccessPopup(true);
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const onClickOk = () => {
    console.log("onClickOk");
    toast.success("successfully updated");
    closePopup();
    setShowSuccessPopup(false);
    closePrDetails();
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        {/* this is are you sure popup */}
        <Modal
          isOpen={isOpenPopup}
          onRequestClose={closePopup}
          className="custom-popup"
          overlayClassName="custom-popup-overlay"
          shouldCloseOnOverlayClick={false}
        >
          {/* <div className="close-icon-div">
            <img
              src={closeIcon}
              alt=""
              onClick={() => closePopup()}
              style={{ cursor: "pointer" }}
            />
          </div> */}
          <center>
            <img src={image} alt="" />
            <h2 className="pt-2 popup-title">{title}</h2>
            <p className="pt-2 popup-text">{text}</p>
            <div className="pt-4">
              <button onClick={() => closePopup()} className="custom-popup-btn">
                {btn1}
              </button>
              <button
                className="custom-popup-submit-btn mx-2"
                onClick={onClickConfirm}
              >
                {btn2}
              </button>
            </div>
          </center>
        </Modal>
        {/* this is success popup */}
        <Modal
          isOpen={successPopup}
          onRequestClose={closeSuccessPopup}
          className="custom-popup"
          overlayClassName="custom-popup-overlay"
          shouldCloseOnOverlayClick={false}
        >
          {/* <div className="close-icon-div">
            <img
              src={closeIcon}
              alt=""
              onClick={() => onClickOk()}
              style={{ cursor: "pointer" }}
            />
          </div> */}
          <center>
            <img src={successIcon} alt="" />
            <h2 className="pt-2 popup-title">{successTitle}</h2>
            <p className="pt-2 popup-text">{successText}</p>
            <div className="pt-3">
              <button
                className="custom-popup-submit-btn mx-2"
                onClick={onClickOk}
              >
                {btn3}
              </button>
            </div>
          </center>
        </Modal>
      </div>
    </>
  );
};

export default CustomPopup;
