import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CancelAndUpdateBtn from "./CancelAndUpdateBtn/CancelAndUpdateBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorInfo,
  updateVendorInfo,
} from "../../Store/Actions/vendor_info";
import { toast } from "react-toastify";
import {
  formatNumberWithCommas,
  handleKeyDown,
  handleOnWheel,
} from "../../Utils/Utils";

const Commercial = ({ editMode, setEditMode, setPageLoading }) => {
  const {
    t,
    // i18n
  } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.UserInfoReducer);
  const [formattedMinJobsOrder, setFormattedMinJobsOrder] = useState("");
  const [formattedMaxJobsOrder, setFormattedMaxJobsOrder] = useState("");

  const {
    register,
    handleSubmit,
    // getValues,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // checkingthe min jobs and max jobs fields before replace
    const formattedData = {
      ...data,
      min_jobs_order:
        typeof data.min_jobs_order === "string" &&
        data.min_jobs_order.includes(",")
          ? data.min_jobs_order.replace(/,/g, "")
          : data.min_jobs_order,
      max_jobs_order:
        typeof data.max_jobs_order === "string" &&
        data.max_jobs_order.includes(",")
          ? data.max_jobs_order.replace(/,/g, "")
          : data.max_jobs_order,
    };

    const min = parseInt(formattedData.min_jobs_order);
    const max = parseInt(formattedData.max_jobs_order);
    if (isNaN(min) || isNaN(max) || min >= max) {
      toast.error(
        "Invalid input. Please ensure minimum value is lower than maximum value."
      );

      return;
    }

    setPageLoading(true);
    const formdata = new FormData();

    formdata.append("min_jobs_order", formattedData?.min_jobs_order);
    formdata.append("max_jobs_order", formattedData?.max_jobs_order);
    formdata.append(
      "company_limit_size_project",
      data.company_limit_size_project
    );
    formdata.append(
      "analyses_process_for_late_delivery",
      data.analyses_process_for_late_delivery
    );
    formdata.append("project_reference", data.project_reference);
    formdata.append("size_specify", data.size_specify);
    formdata.append(
      "specify_project_reference",
      data.specify_project_reference
    );

    try {
      const isSuccess = await dispatch(updateVendorInfo(formdata));

      if (isSuccess?.data?.status === 200) {
        await dispatch(getVendorInfo());
        setEditMode(false);
        setPageLoading(false);
        toast.success(isSuccess?.data?.message);
      } else {
        setPageLoading(false);
        toast.error(isSuccess?.data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error, "Error");
      toast.error(error.message || "Something went wrong");
      setPageLoading(false);
    }
  };

  console.log("test");
  useEffect(() => {
    if (userInfo) {
      Object.keys(userInfo).forEach((key) => {
        setValue(key, userInfo[key]);
        setFormattedMinJobsOrder(
          formatNumberWithCommas(userInfo?.min_jobs_order) || ""
        );
        setFormattedMaxJobsOrder(
          formatNumberWithCommas(userInfo?.max_jobs_order) || ""
        );
      });
    }
  }, [userInfo]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className="genaral-main-div">
          <div className="vp-label-input">
            <label className="vp-label">
              {t(
                "Maximum and Minimum Value of jobs/order that you can accept?"
              )}
              {editMode && <span className="staricon">*</span>} :
            </label>
            {!editMode && (
              <div className="vp-min-max-div">
                <label className="vp-min-label">
                  {t("Minimum")}
                  {editMode && <span className="staricon">*</span>}
                </label>
                <div className="d-block">
                  <input
                    disabled={!editMode}
                    type="text"
                    className="vp-input"
                    {...register("min_jobs_order", {
                      required: true,
                      // validate: (value) =>
                      //   isValidNumber(value) || "Please enter a valid number.",
                    })}
                    value={formattedMinJobsOrder}
                  />

                  {errors.min_jobs_order && (
                    <span className="error-msg">
                      {" "}
                      {t("Please enter the minimum value")}
                    </span>
                  )}
                </div>
                <label className="vp-min-label">
                  {t("Maximum")}
                  {editMode && <span className="staricon">*</span>}
                </label>
                <div className="d-block">
                  <input
                    disabled={!editMode}
                    type="text"
                    className="vp-input"
                    {...register("max_jobs_order", {
                      required: true,
                    })}
                    value={formattedMaxJobsOrder}
                  />

                  {errors.max_jobs_order && (
                    <span className="error-msg">
                      {" "}
                      {t("Please enter the maximum value")}
                    </span>
                  )}
                </div>
              </div>
            )}
            {editMode && (
              <div className="vp-min-max-div">
                <label className="vp-min-label">
                  {t("Minimum")}
                  {editMode && <span className="staricon">*</span>}
                </label>
                <div className="d-block">
                  <input
                    disabled={!editMode}
                    type="text"
                    className="vp-input"
                    {...register("min_jobs_order", {
                      required: true,
                      maxLength: 11,
                    })}
                    value={formattedMinJobsOrder}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                      const formattedValue = formatNumberWithCommas(rawValue);
                      setFormattedMinJobsOrder(formattedValue);
                      setValue("min_jobs_order", rawValue);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />

                  {errors.min_jobs_order && (
                    <span className="error-msg">
                      {" "}
                      {t("Please enter the minimum value")}
                    </span>
                  )}
                </div>
                <label className="vp-min-label">
                  {t("Maximum")}
                  {editMode && <span className="staricon">*</span>}
                </label>
                <div className="d-block">
                  <input
                    disabled={!editMode}
                    type="text"
                    className="vp-input"
                    {...register("max_jobs_order", {
                      required: true,
                      maxLength: 11,
                    })}
                    value={formattedMaxJobsOrder}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                      const formattedValue = formatNumberWithCommas(rawValue);
                      setFormattedMaxJobsOrder(formattedValue);
                      setValue("max_jobs_order", rawValue);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />

                  {errors.max_jobs_order && (
                    <span className="error-msg">
                      {" "}
                      {t("Please enter the maximum value")}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="vp-label-input">
            <label className="vp-label">
              {t("Does your company limit on the size of works or projects?")}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("company_limit_size_project", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="No">{t("No")}</option>
                <option value="Yes">{t("Yes, in value....")}</option>
              </select>
              {errors.company_limit_size_project && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("company_limit_size_project") === "Yes" && (
                  <>
                    <input
                      type="number"
                      placeholder="please enter value as a number"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("size_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                      onWheel={handleOnWheel}
                    />

                    {errors.size_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="vp-label-input">
            <label className="vp-label">
              {t("Project reference / Work reference")}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("project_reference", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="no">{t("No")}</option>
                <option value="yes">{t("Yes , please specify")}</option>
              </select>
              {errors.project_reference && (
                <span className="error-msg">
                  {t("Please select the correct value")}{" "}
                </span>
              )}
              <div className="vp-input-div" style={{ display: "contents" }}>
                {watch("project_reference") === "yes" && (
                  <>
                    <input
                      type="text"
                      className="vp-input mt-3"
                      disabled={!editMode}
                      {...register("specify_project_reference", {
                        required: true,
                        maxLength: 80,
                      })}
                    />

                    {errors.specify_project_reference && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="vp-label-input mb-5">
            <label className="vp-label">
              {t(
                "Does your company have the procedure to analyses the late delivery of goods and/or service failure"
              )}
              {editMode && <span className="staricon">*</span>} :
            </label>
            <div className="vp-input-div">
              <select
                disabled={!editMode}
                className="vp-input"
                {...register("analyses_process_for_late_delivery", {
                  required: true,
                })}
              >
                <option className="dropdown-country" value="" hidden>
                  {" "}
                  {t("select")}
                </option>
                <option value="Yes">{t("Yes")}</option>
                <option value="No">{t("No")}</option>
              </select>
              {errors.analyses_process_for_late_delivery && (
                <span className="error-msg">
                  {t("Please select the correct value")}
                </span>
              )}
            </div>
          </div>
          <CancelAndUpdateBtn
            editMode={editMode}
            setEditMode={setEditMode}
            reset={reset}
          />
        </div>
      </form>
    </>
  );
};

export default Commercial;
